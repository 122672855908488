import { getCardLogoByType } from '@/utils/cards';
import { CardInfo, CardItemWrapper, CardLogo, CardNumber, ExpiredCard } from './styled';

const CardItem: React.FC<any> = ({ card }) => {
  const logo = getCardLogoByType(card?.brand, true);
  const month = ("0" + card?.exp_month).slice(-2);
  return (
    <CardItemWrapper>
      <CardInfo>
        <CardLogo src={logo} width={25} height={16} />
        <CardNumber>•••• <span className="fs-mask">{card?.last4}</span></CardNumber>
      </CardInfo>
      <ExpiredCard>
        EXP <span className="fs-mask">{month}/{card?.exp_year?.toString().slice(2)}</span>
      </ExpiredCard>
    </CardItemWrapper>
  );
};
export default CardItem;
