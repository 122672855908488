import { styled } from '@mui/material/styles';

export const LoadingWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.common.black,
  zIndex: 1050,
  opacity: 0.8
}));

export const InnerWrapper = styled('div')({
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: 'relative',
});

export const ImgWrapper = styled('img')({
  '@keyframes spin': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
  position: 'absolute',
  left: 0,
  right: 0,
  margin: '-30px auto 0',
  top: '50%',
  width: '60px',
  height: '60px',
  objectFit: 'contain',
  animation: `spin 2s infinite linear`,
});
