import { Button } from '@/components/common';
import { styled } from '@mui/system';
import { Form } from 'formik';

export const StyledForm = styled(Form, { name: 'Form' })(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  flex: '1 0',
  [theme.breakpoints.down('lg')]: {
    justifyContent: 'space-between',
  },
}));

export const Title = styled('div')(({ theme }) => ({
  fontWeight: '500',
  lineHeight: '1.2',
  letterSpacing: '3px',
  fontFamily: 'FuturaLTPro-Medium',
  fontSize: '32px',
  color: '#fff',
  textTransform: 'uppercase',
}));

export const ExpireLicenseWrapper = styled('div')(({ theme }) => ({
  marginTop: '32px',
  display: 'flex',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export const ExpireDateWrapper = styled('div', {
  shouldForwardProp: (propName: string) => propName !== 'left',
})<{ left: boolean }>(({ theme, left }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  ...(left && {
    marginRight: '32px',
  }),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'rows',
    marginRight: '0px',
    ...(left && {
      marginBottom: '32px',
    }),
  },
}));

export const SubTitle = styled('div')(({ theme }) => ({
  fontWeight: '500',
  lineHeight: '1.6',
  letterSpacing: '1px',
  fontFamily: 'FuturaLTPro-Book',
  fontSize: '16px',
  color: '#999',
  marginTop: '32px',
}));

export const ButtonWrapper = styled(Button)(({ theme }) => ({
  marginBottom: 0,
  [theme.breakpoints.up('sm')]: {
    marginBottom: 40,
  },
}));
