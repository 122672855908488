import { Checkbox } from '@/components/common';
import React from 'react';
import { TextWrapper, Wrapper } from './styled';

const CheckboxTerm: React.FunctionComponent<any> = ({
  checked = false,
  onClick,
  text,
  testId,
  mode = 'dark',
}: {
  checked: boolean;
  onClick: () => void;
  text: string;
  testId?: string;
  mode?: string;
}) => {
  return (
    <Wrapper onClick={() => onClick()}>
      <Checkbox checked={checked} data-testid={testId} mode={mode} />
      <TextWrapper mode={mode}>{text}</TextWrapper>
    </Wrapper>
  );
};
export default CheckboxTerm;
