//@ts-nocheck
import { reloadUser } from '@/api/user';
import { useAppDispatch } from '@/redux/hooks';

export const useUser = (uid: string) => {
  const dispatch = useAppDispatch();
  if (!uid) return [];

  const reloadUserState = async () => {
    const response = await reloadUser(uid).then((data) => {
      dispatch({ type: 'user/setUserInfo', payload: data });
      return data;
    });
    return response;
  };
  return [reloadUserState];
};
