import FormLabel from '../Label';
import { StyledTextField, TextFieldWrapper } from './styled';

const StripeTextField = ({ label, ...other }: any) => {
  return (
    <TextFieldWrapper>
      <FormLabel text={label} />
      <StyledTextField {...other} />
    </TextFieldWrapper>
  );
};

export default StripeTextField;
