import { styled } from '@mui/material';
import VerificationInput from 'react-verification-code-input';
export const ErrorMessage = styled('div')(() => ({
  color: '#e54155',
  marginTop: 16,
  fontSize: 13,
  lineHeight: 1.6,
}));
export const VerificationInputStyled = styled(VerificationInput)(() => ({
  '& input::placeholder': {
    color: '#000',
    opacity: '0.1',
  },
}));
