import { Button } from '@/components/common';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CardItem from './CardItem';
import { ModalHeader, PaymentTitle, PaymentContent } from './styled';

const PaymentFailedModal: React.FC<any> = ({ card, closeModal }) => {
  const { t } = useTranslation();
  const onClose = () => {
    closeModal();
  };

  return (
    <>
      <ModalHeader color="primary" variant="title">
        {t('PAYMENT_FAIl_MODAL_TITLE')}
        <img
          style={{ width: '20px', height: '20px', cursor: 'pointer' }}
          src="/images/icon/ico_close_x.svg"
          onClick={onClose}
          alt=""
        />
      </ModalHeader>
      <PaymentContent color="secondary" variant="content" size="15">
        {t('PAYMENT_FAIl_MODAL_SUBTITLE')}
      </PaymentContent>
      <PaymentTitle>{t('PAYMENT_FAIl_PAYMENT_TITLE')}</PaymentTitle>
      <CardItem card={card} />
      <Button fullWidth variant="contained" color="dark" onClick={onClose} sx={{
        boxShadow: 'unset', '&:hover': {
          boxShadow: 'unset'
        }
      }}>
        {t('PAYMENT_FAIl_TRY_BTN')}
      </Button>
    </>
  );
};

export default PaymentFailedModal;
