import { styled } from '@mui/material';

export const CardItemWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  backgroundColor: '#191919',
  padding: '16px 16px',
  marginBottom: 16,
}));
export const CardInfo = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));
export const CardNumber = styled('div')(({ theme }) => ({
  display: 'inline-block',
  fontFamily: 'FuturaLTPro-Medium',
  fontSize: '13px',
  fontWeight: 500,
  lineHeight: 1.23,
  letterSpacing: '1px',
  color: '#fff',
  marginLeft: 24,
}));
export const CardLogo = styled('img')(({ theme }) => ({
  display: 'inline-block',
}));
export const ExpiredCard = styled('div')(({ theme }) => ({
  color: '#999',
  fontSize: '13px',
  fontFamily: 'FuturaLTPro-Medium',
}));
