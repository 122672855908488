import { useAppSelector } from '@/redux/hooks';
import { UserType } from '@/utils/constant';
import { getPaymentMethodLabelForSideBar } from '@/utils/helper';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CircleCheckbox from '../CircleCheckbox';
import Logo from '../Logo';
import { ACTIVE, NOT_ACTIVE, VERIFIED } from './constant';
import {
  ContentWrapper,
  Radio,
  RadioWrapper,
  Step,
  StepText,
  StepWrapper,
  StyledLinearProgress,
  VerticalDash,
  Wrapper,
} from './styles';

const Sidebar: React.FunctionComponent<any> = (props: any) => {
  const { t } = useTranslation();
  const stepStatus = useAppSelector((state: any) => state.sidebar);
  const currentStep = useAppSelector((state: any) => state.sidebar.currentStep);
  const userType = useAppSelector((state: any) => state.user.userType);
  const isAddPaymentMethodForCP = useAppSelector((state: any) => state.user.isAddPaymentMethod);

  const checkStatus = (index: number, stepName: string) => {
    if (currentStep === index) return ACTIVE;
    return currentStep > index ? VERIFIED : NOT_ACTIVE;
  };

  const checkDepositStatus = (index: number) => {
    if (!stepStatus['deposit_passed'] && currentStep === index) return ACTIVE;
    return stepStatus['deposit_passed'] ? VERIFIED : NOT_ACTIVE;
  };

  const STEP = [
    {
      status: checkStatus(0, 'create_account_passed'),
      content: userType !== UserType.CONCIERGE_PORTAL ? t('CREATE_AN_ACCOUNT') : null,
    },
    {
      status: checkStatus(1, 'verify_phone_passed'),
      content: t('VERIFY_YOUR_NUMBER'),
    },
    {
      status: checkStatus(2, 'verify_identity_passed'),
      content: t('VERIFY_YOUR_IDENTITY'),
    },
    {
      status: checkStatus(3, 'insurance_declaration_passed'),
      content: t('INSURANCE_DECLARATION'),
    },
    {
      status: checkDepositStatus(4),
      content: t(getPaymentMethodLabelForSideBar(userType, isAddPaymentMethodForCP)),
    },
  ].filter((item) => !!item.content);

  const progressBar = useMemo(() => {
    const newCurrentStep = userType === UserType.CONCIERGE_PORTAL ? currentStep : currentStep + 1;
    return (newCurrentStep / STEP.length) * 100;
  }, [currentStep, STEP.length, userType]);

  return (
    <>
      <Wrapper>
        <Logo theme="light" />
        <ContentWrapper>
          <VerticalDash />
          <StepWrapper>
            {STEP.map((item, index) => {
              const stepIndex = userType !== UserType.CONCIERGE_PORTAL ? index : index + 1;
              return (
                <Step key={stepIndex} disable={stepIndex !== currentStep}>
                  <RadioWrapper>
                    <CircleCheckbox
                      disabled={item.status === NOT_ACTIVE}
                      checked={item.status === VERIFIED}
                      size="medium"
                    />
                  </RadioWrapper>
                  <StepText>{item.content}</StepText>
                </Step>
              );
            })}
          </StepWrapper>
          <Step disable style={{ marginBottom: '0px' }}>
            <RadioWrapper>
              <Radio src="/images/sidebar/icon-menu-car.svg" alt="" />
            </RadioWrapper>
            {/* <RadioStatus src="/images/sidebar/icon-menu-car.svg" alt="" /> */}
            <StepText>{t('READY_TO_DRIVE')}</StepText>
          </Step>
        </ContentWrapper>
      </Wrapper>
      <StyledLinearProgress variant="determinate" value={progressBar} />
    </>
  );
};
export default Sidebar;
