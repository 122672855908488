import dayjs from 'dayjs';
import * as yup from 'yup';
import {
  REGEX_EMAIL,
  REGEX_CHARACTERS,
  REGEX_NUMBER,
  REGEX_SPECIAL_CHARACTERS,
  REGEX_NAME,
  REGEX_SPECIAL_CHARACTERS_FOR_NAME_FIELD,
} from './constant';

export const EmailSchema = yup
  .string()
  .required('Email is required')
  .test('', 'Special characters is not allowed', (email) => {
    return !(
      email &&
      !email.match(REGEX_EMAIL) &&
      email.split('@').length === 2 &&
      email.split('@')[0] &&
      !email.split('@')[0].match(REGEX_NAME)
    );
  })
  .matches(REGEX_EMAIL, 'This email is invalid, please try another')
  .test('', 'Email must less than 64 characters long', (email) => {
    return !(email && email.match(REGEX_EMAIL) && email.split('@')[0]?.length > 64);
  });
export const PasswordSchema = yup.string().required('Please enter your password');
export const PasswordPolicySchema = yup
  .string()
  .required('Password is required')
  .test('', 'Password too weak', (password) => {
    return !(password && !password.match(REGEX_CHARACTERS));
  })
  .test('', 'Password too weak', (password) => {
    return !(password && password.length <= 9);
  })
  .test('', 'Password too weak', (password) => {
    return !(password && !(password.match(REGEX_NUMBER) && password.match(REGEX_SPECIAL_CHARACTERS)));
  })
  .test('', 'Must not contain password word', (password) => {
    return !(password && password.toLowerCase().includes('password'));
  })
  .test('', 'Cannot include your name/email', function (password) {
    const email = this.parent.email?.toLowerCase();
    const name = email?.split('@')[0];
    const isEmail = password && password.toLowerCase().includes(email);
    const isName = password && password.toLowerCase().includes(name);
    return !(isEmail || isName);
  });

export const PhoneNumberSchema = yup
  .string()
  .required('Phone number is required')
  .test('', 'Invalid phone number', (phoneNumber) => {
    return !(phoneNumber && phoneNumber.length > 15);
  });

export const FirstNameSchema = yup
  .string()
  .required('First name is required')
  .test('', 'Special characters is not allowed', (fname) => {
    return !(fname && !fname.match(REGEX_SPECIAL_CHARACTERS_FOR_NAME_FIELD));
  });

export const LastNameSchema = yup
  .string()
  .required('Last name is required')
  .test('', 'Special characters is not allowed', (fname) => {
    return !(fname && !fname.match(REGEX_SPECIAL_CHARACTERS_FOR_NAME_FIELD));
  });

export const BirthdateSchema = yup
  .string()
  .required('Date of birth is required')
  .test('', 'Date of birth is invalid', (date) => {
    if (!date) return false;
    const dateConverted = dayjs(date, 'DD/MM/YYYY');
    return (
      !!dateConverted.isValid() &&
      !!dayjs(dateConverted).isBefore(dayjs()) &&
      !!dayjs(dateConverted).isAfter(dayjs('12-31-1599', 'MM-DD-YYYY'))
    );
  });
export const IssuingDateSchema = yup
  .string()
  .required('Issuing date is required')
  .test('', 'Issuing date is invalid', (date) => {
    if (!date) return false;
    const dateConverted = dayjs(date, 'DD/MM/YYYY');
    return !!dateConverted.isValid() && !!dayjs(dateConverted).isAfter(dayjs('12-31-1599', 'MM-DD-YYYY'));
  });

export const DateExpireSchema = yup
  .string()
  .required('Valid until is required')
  .test('', 'Valid until is invalid', (date) => {
    if (!date) return false;
    const dateConverted = dayjs(date, 'DD/MM/YYYY');
    return !!dateConverted.isValid() && !!dayjs(dateConverted).isAfter(dayjs('12-31-1599', 'MM-DD-YYYY'));
  })
  .test('', 'Your driving licence has expired', (date) => {
    if (!date) return false;
    const dateConverted = dayjs(date, 'DD/MM/YYYY');
    return !!dayjs(dateConverted).isAfter(dayjs());
  });
export const cardNameSchema = yup.string().trim().required('Card Name is required.');
export const PostcodeSchema = yup.string().trim().required('Postcode is required.');
