import { styled } from '@mui/material/styles';

export const SuccessPopupWrapper = styled('div')(({ theme }) => ({
  backgroundColor: '#22c55e',
  borderRadius: 40,
  display: 'flex',
  height: 48,
}));

export const SuccessPopupIconWrapper = styled('div')(({ theme }) => ({
  paddingLeft: 24,
  display: 'flex',
  alignItems: 'center'
}));

export const SuccessPopupMessageWrapper = styled('div')(({ theme }) => ({
  color: '#000000',
  paddingLeft: 8,
  fontSize: 11,
  lineHeight: 1.45,
  fontFamily: 'FuturaLTPro-Book',
  textTransform: 'uppercase',
  display: 'flex',
  alignItems: 'center',
  paddingRight: 24
}));
