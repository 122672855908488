/* eslint-disable @typescript-eslint/no-unused-vars */
//@ts-nocheck
import React from 'react';
import { ModalContent, ModalBody, OverlayLayerWrapper } from './styles';
import classnames from 'classnames';
import Overlay from './Overlay';

export const ModalComponent = React.forwardRef((propsValues, ref) => {
  const {
    component: RenderInner,
    props,
    closable = true,
    onClose = () => {},
    closeModal = () => {},
    width = 480,
    theme = 'black',
    title,
    closeable = true,
    closeIcon = false,
    onSubmit,
    type = 'normal', // normal || failure
    isDeposits,
    padding = { large: 40, small: 24 },
    inMobile = 'full',
  } = propsValues;

  return (
    <Overlay overlayOpacity={0.5} noDisableScroll>
      <OverlayLayerWrapper
        className={classnames('noPaddingTop', {
          customOverlay: isDeposits,
        })}
        inMobile={inMobile}
      >
        <ModalContent variant={theme} width={width} inMobile={inMobile} padding={padding}>
          <ModalBody className="fs-unmask">
            {RenderInner && <RenderInner inModal={true} closeModal={closeModal} {...props} />}
          </ModalBody>
        </ModalContent>
      </OverlayLayerWrapper>
    </Overlay>
  );
});
