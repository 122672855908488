import { Text } from '@/components/common';
import { Button } from '@/components/common';
import { styled } from '@mui/material';

export const MessageWrapper = styled('div')(({ theme }) => ({
  fontFamily: 'FuturaLTPro-Medium',
  fontSize: '15px',
  fontWeight: 'normal',
  lineHeight: 1.6,
  letterSpacing: '1px',
  color: '#555555'
}));

export const ModalHeader = styled(Text)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    marginTop: '8px',
  },
}));

export const ContinueBtnRemove = styled(Button)(({ theme }) => ({
  marginTop: 24,
  backgroundColor: '#000',
  color: '#fff',
  boxShadow: 'unset',
  '&:hover': {
    backgroundColor: '#000',
    color: '#fff',
    boxShadow: 'unset'
  },
}));
