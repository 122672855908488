import { IconError } from '@/components/icons';
import { CONTACT_EMAIL } from '@/utils/constant';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ModalButton, ModalHeader, Wrapper, WrapperMessage, WrapperTitle } from './styled';

const AdditionalDriverErrorModal: React.FC<any> = ({ closeModal }) => {
  const { t } = useTranslation();

  const handleClose = () => {
    window.location.href = CONTACT_EMAIL;
    closeModal();
  };

  return (
    <Wrapper>
      <ModalHeader>
        <IconError width={32} height={32}/>       
      </ModalHeader>
      <WrapperTitle color="primary" variant="titleModal">
        {t('NOT_ELIGIBLE_TO_DRIVE_TITLE')}
      </WrapperTitle>
      <WrapperMessage color="secondary" variant="content" size="15">
        <Trans
          i18nKey="NOT_ELIGIBLE_TO_DRIVE_MESSAGE"
          components={{
            // eslint-disable-next-line jsx-a11y/anchor-has-content, react/jsx-no-target-blank
            a: <a target="_blank" href={t('NOT_ELIGIBLE_TO_DRIVE_POLICY')} />,
          }}
        />
      </WrapperMessage>
      <ModalButton variant="contained" color="dark" onClick={handleClose}>
        {t('CONTACT_US')}
      </ModalButton>
    </Wrapper>
  );
};

export default AdditionalDriverErrorModal;
