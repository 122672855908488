import { Button, Text } from '@/components/common';
import { NOT_ACTIVE, PENDING, REJECTED, VERIFIED } from '@/components/common/Sidebar/constant';
import { FormLabel, FormMarginDense, FormWrapper } from '@/components/form';
import KYCElement from '@/components/page/Identity/KycElement';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { get } from 'lodash';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { KYCLayout } from '../../container/KYCLayout';
import { useOnfido } from '../../hook/useOnfido';
import { useUser } from '../../hook/useUser';
import { ContentWrapper } from './styles';

const VerifyIdentity: any = () => {
  const { t } = useTranslation();
  const userInfo = useAppSelector((state: any) => get(state, 'user.userInfo'));
  const requiredKYC = get(userInfo, 'insurance.requires');
  const issuingCountry = get(userInfo, 'licence.issuing_country');
  const [, , , nextStep] = useOnfido(userInfo?.id);
  const [reloadUser] = useUser(userInfo?.id);
  const dispatch = useAppDispatch();
  const [isNotStartedKYC, setStartedKYC] = useState<boolean>(true);
  useLayoutEffect(() => {
    if (requiredKYC) {
      const isNStartedKYC =
        requiredKYC?.filter((item: string) => {
          return item !== 'User.passport' && item !== 'User.selfie-in-verification';
        }).length === 3;
      setStartedKYC(isNStartedKYC);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requiredKYC]);

  useEffect(() => {
    dispatch({ type: 'HIDE_LOADING' });
    reloadUser && reloadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const KycElementData = [
    {
      ico: 'licence',
      content: t('DRIVING_LICENCE'),
      status: !!requiredKYC?.includes('User.licence') ? NOT_ACTIVE : VERIFIED,
    },
    {
      ico: 'selfie',
      content: t('FACIAL_VERIFICATION'),
      status: !!requiredKYC?.includes('User.selfie-in-verification')
        ? PENDING
        : !!requiredKYC?.includes('User.selfie')
          ? NOT_ACTIVE
          : VERIFIED,
    },
    {
      ico: 'home',
      content: t('HOME_ADDRESS'),
      status: !!requiredKYC?.includes('User.address') ? NOT_ACTIVE : VERIFIED,
    },
    {
      ico: 'licence',
      content: !issuingCountry || issuingCountry === 'GBR' ? null : t('PASSPORT'),
      status: !!requiredKYC?.includes('User.passport') ? NOT_ACTIVE : VERIFIED,
    },
  ].filter((item) => item.content);

  const handleSubmit = async () => {
    dispatch({ type: 'SHOW_LOADING' });
    await nextStep(0);
  };

  const verifiedStep = KycElementData?.filter((item) => item.status === VERIFIED);
  const stillRequiredStep = KycElementData?.filter((item) => item.status === NOT_ACTIVE || item.status === PENDING);
  const rejectedStep = KycElementData?.filter((item) => item.status === REJECTED);

  return (
    <FormWrapper>
      <div id="onfido-mount"></div>
      <Text color="darkPrimary" variant="title">
        {t('VERIFY_IDENTITY')}
      </Text>
      <Text variant="content" color="darkSecondary">
        {t('VERIFY_IDENTITY_SUBTITLE')}
      </Text>
      <ContentWrapper>
        <div>
          {isNotStartedKYC ? (
            <>
              <FormMarginDense>
                <FormLabel text={t('REQUIRED')} />
              </FormMarginDense>
              <FormMarginDense value="8">
                {!!requiredKYC &&
                  KycElementData.map((item, index) => {
                    return <KYCElement key={index} isLast={index === KycElementData.length - 1} {...item} />;
                  })}
              </FormMarginDense>
            </>
          ) : (
            <>
              {verifiedStep && verifiedStep.length ? (
                <>
                  <FormMarginDense>
                    <FormLabel text={t('VERIFIED')} />
                  </FormMarginDense>
                  <FormMarginDense value="8">
                    {verifiedStep.map((item, index) => {
                      return <KYCElement key={index} isLast={index === verifiedStep.length - 1} {...item} />;
                    })}
                  </FormMarginDense>
                </>
              ) : null}
              {stillRequiredStep && stillRequiredStep.length ? (
                <>
                  <FormMarginDense>
                    <FormLabel text={t('STILL_REQUIRED')} />
                  </FormMarginDense>
                  <FormMarginDense value="8">
                    {stillRequiredStep.map((item, index) => {
                      return (
                        <KYCElement
                          key={index}
                          isLast={index === stillRequiredStep.length - 1}
                          {...item}
                          status={PENDING}
                        />
                      );
                    })}
                  </FormMarginDense>
                </>
              ) : null}
              {rejectedStep.length ? (
                <>
                  <FormMarginDense>
                    <FormLabel text={t('REJECTED')} />
                  </FormMarginDense>
                  <FormMarginDense value="8">
                    {rejectedStep.map((item, index) => {
                      return <KYCElement key={index} isLast={index === rejectedStep.length - 1} {...item} />;
                    })}
                  </FormMarginDense>
                </>
              ) : null}
            </>
          )}
        </div>
        {!!requiredKYC && <FormMarginDense>
          <Button fullWidth variant="contained" color="white" type="submit" onClick={handleSubmit}>
            {isNotStartedKYC ? t('START_VERIFICATION') : t('CONTINUE_VERIFICATION')}
          </Button>
        </FormMarginDense>}
      </ContentWrapper>
    </FormWrapper>
  );
};

VerifyIdentity.getLayout = KYCLayout;
export default VerifyIdentity;
