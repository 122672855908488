import { getCardLogoByType } from '@/utils/cards';
import { getExpireDate } from '@/utils/helper';
import { CardInfo, CardItemWrapper, CardLogo, CardNumber, ExpireDateWrapper } from './styled';

const CardItem: React.FC<any> = ({ card }) => {
  const logo = getCardLogoByType(card?.type, true);
  const expireDate = getExpireDate(card?.month?.toString(), card?.year?.toString());
  return (
    <CardItemWrapper>
      <CardInfo>
        <CardLogo src={logo} width={25} height={16} />
        <CardNumber>
          •••• <span className="fs-mask">{card?.number}</span>
        </CardNumber>
      </CardInfo>
      <ExpireDateWrapper>
        <span className="fs-mask">EXP {expireDate}</span>
      </ExpireDateWrapper>
    </CardItemWrapper>
  );
};
export default CardItem;
