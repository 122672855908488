import { styled } from '@mui/system';
import { Form } from 'formik';

export const StyledForm = styled(Form, { name: 'Form' })(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  flex: '1 0',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'space-between',
  },
}));
