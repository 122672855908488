import { styled } from '@mui/material';

export const ModalBody = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

export const OverlayLayer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
}));

export const ModalContent = styled('div', {
  shouldForwardProp: (propName: string) =>
    propName !== 'width' && propName !== 'padding' && propName !== 'variant' && propName !== 'inMobile',
})<{ width: string; padding: string }>(({ theme, width = 500, padding, inMobile, variant }) => ({
  width: `${width}px`,
  ...(padding && {
    padding: `${padding.large}px`,
  }),
  ...(variant === 'white' && {
    backgroundColor: '#fff',
    color: '#000',
  }),
  ...(variant === 'black' && {
    backgroundColor: '#0E0E0E',
    color: '#fff',
  }),

  [theme.breakpoints.down('sm')]: {
    ...(inMobile === 'full' && {
      height: '100vh',
      width: '100vw',
    }),
    ...(inMobile === 'bottom' && {
      width: '100vw',
    }),
    padding: `${padding.small}px`,
  },
}));

export const ModalWrapper = styled('div', {
  shouldForwardProp: (propName: string) => propName !== 'padding' && propName !== 'primary',
})<{ primary: boolean; padding: string }>(({ theme, padding = '32px', primary }) => ({
  flexDirection: 'column',
  justifyContent: 'space-between',
  position: 'relative',
  padding: `${padding}px`,
  ...(primary
    ? {
        height: '100%',
      }
    : {
        height: 'auto',
      }),
}));

export const Wrapper = styled('div')(({ theme }) => ({
  textAlign: 'left',
  display: 'inline-block',
}));

export const OverlayLayerWrapper = styled(OverlayLayer, {
  shouldForwardProp: (propName: string) => propName !== 'inMobile',
})<{ width: string; padding: string }>(({ theme, inMobile }) => ({
  ...(inMobile === 'full' && {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0px',
    },
  }),
  ...(inMobile === 'bottom' && {
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-end',
    },
  }),
}));
// export const OverlayLayerWrapper = styled(OverlayLayer)`
//   ${(props) =>
//     props.inMobile === 'full'
//       ? `
//     @media screen and (max-width: '768px) {
//       padding-top: '0px',
//     }
// `
//       : 'null}
//   ${(props) =>
//     props.inMobile === 'bottom'
//       ? `
//         @media screen and (max-width: '768px) {
//           align-items: 'flex-end',
//         }
//     `
//       : 'null}

//   ${(props) => props.customOverlayWrapperStyle}
// }));
