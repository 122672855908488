import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { BrowserRouter } from 'react-router-dom';
import RootContainer from './app/RootContainer';
import 'react-toastify/dist/ReactToastify.css';
import { Zoom } from 'react-toastify';
import { ToastContainer } from 'react-toastify';


const App = () => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || '');
  const options = {
    fonts: [
      {
        cssSrc: 'https://static.theout.com/website/wf/fonts/FuturaLTPro-Book/styles.css',
      },
    ],
  };
  return (
    <>

      <BrowserRouter>
        <ToastContainer
          autoClose={3000}
          position="bottom-center"
          newestOnTop={true}
          transition={Zoom}
          limit={1}
        />
        <Elements stripe={stripePromise} options={options}>
          <RootContainer />
        </Elements>
      </BrowserRouter>
    </>
  );
};
export default App;
