/* eslint-disable no-useless-escape */
export enum UserType {
  ADDITIONAL_DRIVER = 0,
  CONCIERGE_PORTAL = 1,
  RENTAL_CARS = 2,
}
export const REGEX_DATE =
  /^(?:(?:31(\/)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;

export const REGEX_SPECIAL_CHARACTERS_FOR_NAME_FIELD = new RegExp(
  `${process.env.REACT_APP_REGEX_SPECIAL_CHARACTERS_FOR_NAME_FIELD}$`,
  'u',
);
// eslint-disable-next-line
export const REGEX_CHARACTERS = /(?=.*[a-z])(?=.*[A-Z])/;
// eslint-disable-next-line
export const REGEX_SPECIAL_CHARACTERS = /^(?=.*[0-9])(?=.*[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])/;
export enum ERROR_CODES {
  TOO_MANY_REQUESTS = 'TooManyRequests',
  EXISTING_EMAIL = 'EMAIL_EXISTS',
  INVALID_PHONE_NUMBER = 'auth/invalid-phone-number',
  INVALID_PHONE = 'InvalidPhone',
  INVALID_CODE = 'auth/invalid-verification-code',
  CODE_EXPIRED = 'auth/code-expired',
  EXISTING_PHONE_NUMBER = 'InvalidPhoneNumber',
  EXISTING_PHONE_NUMBER_2 = 'PhoneNumberExists',
  TOO_MANY_REQUEST_CODE = 'auth/too-many-requests',
  TOO_MANY_ATTEMPS_CODE = 'auth/too-many-attempts-try-later',
  INVALID_EMAIL = 'InvalidEmail',
  USER_NOT_FOUND = 'auth/user-not-found',
  INVALID_PASSWORD = 'InvalidPassword',
  EXPIRED_CODE = 'Verification code is expired, please resend again',
  USER_DISABLE = 'UserDisabled',
  USER_DISABLED = 'auth/user-disabled',
  PRIVACY_NO_POLICY = 'PrivacyNoPolicy',
}
export const REGEX_EMAIL =
  // eslint-disable-next-line
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i; // RFC 5322 Official Standard
export const REGEX_NUMBER = /(?=.*[0-9])/;
// eslint-disable-next-line
export const REGEX_NAME = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))$/;

export enum PHONE_STATUS {
  NOT_VERIFIED = 'NOT_VERIFIED',
  VERIFIED = 'VERIFIED',
}

export enum CP_PHONE_STATUS {
  NOT_VERIFIED = 0,
  VERIFIED = 1,
}

export const VERIFY_PHONE_PATH = '/verification-phone';

export enum CARD_TYPE {
  APPLE = 'apple_pay',
  CREDIT_CARD = 'credit_card',
}

export const LOKALISE_DEFAULT_LANG = 'en';

export const LOGO_WIDTH = {
  theout: {
    dark: {
      xxs: '128',
      sm: '160',
    },
    light: {
      xxs: '128',
      sm: '160',
    },
  },
  quintessentially: {
    dark: {
      xxs: '289',
      sm: '325',
    },
    light: {
      xxs: '289',
      sm: '208',
    },
  },
  default: {
    xxs: '128',
    sm: '160',
  },
};

export enum LOGO_NAME {
  THEOUT = 'theout',
  QUINTESSENTIALlY = 'quintessentially',
}

export enum THEME {
  DARK = 'dark',
  LIGHT = 'light',
}

export const CONTACT_EMAIL = 'mailto:hello@theout.com';
