import { styled } from '@mui/material';

export const CardSecurity = styled('div', {
  shouldForwardProp: (propName: string) => propName !== 'value',
})<{ mode: string }>(({ theme, mode='page' }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    ...(mode !== 'page' && {
      flexDirection: 'column'
    }),
  },
}));
export const CardIcon = styled('img')(() => ({
  marginLeft: 8,
}));

export const FormMarginRightDense = styled('div', {
  shouldForwardProp: (propName: string) => propName !== 'value',
})<{ value: string, mode: string, marginTop: boolean }>(({ theme, value, mode='page', marginTop= true }) => ({
  marginRight: `${value ?? 24}px`,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginRight: `${value ?? 32}px`,
  },
  [theme.breakpoints.down('sm')]: {
    ...((mode !== 'page') && {
      ...(marginTop && {
        marginTop: 24
      }),
      ...(!marginTop && {
        marginTop: 0
      }),
    }),
  },
  [theme.breakpoints.up('sm')]: {
    ...((mode !== 'page') && {
     width: '43.09% !important'
    }),
  },
}));

export const PostCodeWrapper = styled('div', {
  shouldForwardProp: (propName: string) => propName !== 'value',
})<{mode: string, marginTop: boolean }>(({ theme, mode='page', marginTop= true }) => ({
  [theme.breakpoints.down('sm')]: {
    ...((mode !== 'page') && {
      ...(marginTop && {
        marginTop: 24
      }),
      ...(!marginTop && {
        marginTop: 0
      }),
    }),
  },
}));

export const PaymentFormWrapper = styled('div')(() => ({
  display: 'block',
}));
