import CardItem from './CardItem';
import { PaymentTitle, PaymentWrapper } from './styled';

const CardList: React.FC<any> = ({ cardList }) => {
  return (
    <>
      <PaymentWrapper>
        <PaymentTitle>Payment Method</PaymentTitle>
        {cardList?.map((card: any, index: number) => (
          <CardItem key={index} card={card} />
        ))}
      </PaymentWrapper>
    </>
  );
};
export default CardList;
