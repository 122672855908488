//@ts-nocheck
import { Text } from '@/components/common';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModalButton, ModalHeader, Wrapper, WrapperText } from './styled';
import { CONTACT_EMAIL } from '@/utils/constant';

const PendingKycModal: React.FC<any> = ({ closeModal }) => {
  const { t } = useTranslation();

  const handleClose = () => {
    window.location.href = CONTACT_EMAIL;
    closeModal();
  };

  return (
    <Wrapper>
      <ModalHeader color="primary" variant="title" size="18" style={{ marginBottom: 16 }}>
        <img style={{ width: '40px', height: '40px' }} src="/images/icon/ico_identity_large.svg" alt="" />
      </ModalHeader>
      <WrapperText>
        <Text
          color="primary"
          variant="titleModal"
          style={{ margin: '0px auto 16px auto', maxWidth: '199px', width: '100%', textAlign: 'center' }}
        >
          {t('IDENTITY_BEING_VERIFIED')}
        </Text>
        <Text color="secondary" variant="content" size="15">
          {t('PLEASE_CONTACT_US_24_HOUR')}
        </Text>
      </WrapperText>
      <ModalButton variant="contained" color="dark" onClick={handleClose}>
        {t('CONTACT_US')}
      </ModalButton>
    </Wrapper>
  );
};

export default PendingKycModal;
