import { styled } from '@mui/system';

export const FormMarginDense = styled('div', {
  shouldForwardProp: (propName: string) => propName !== 'value',
})<{ value: string, mode: string, marginTop: boolean }>(({ theme, value, mode='page', marginTop= true }) => ({
  marginTop: `${value ?? 24}px`,
  [theme.breakpoints.up('sm')]: {
    marginTop: `${value ?? 32}px`,
  },
  [theme.breakpoints.down('sm')]: {
    ...((mode !== 'page') && {
      ...(marginTop && {
        marginTop: 24
      }),
      ...(!marginTop && {
        marginTop: 0
      }),
    }),
  },
  [theme.breakpoints.up('sm')]: {
    ...((mode !== 'page') && {
      marginTop: 24
    }),
  },
}));
