import creditCardType from 'credit-card-type';
export const MAX_LENGTH_SECURITY_CODE_AMEX = 4;
export const MAX_LENGTH_SECURITY_CODE_DEFAULT = 3;
export const CardName = creditCardType.types;
export const TYPE_MAPPING: any = {
  visa: CardName.VISA,
  amex: CardName.AMERICAN_EXPRESS,
  mastercard: CardName.MASTERCARD,
  diners: CardName.DINERS_CLUB,
  jcb: CardName.JCB,
  discover: CardName.DISCOVER,
  unionpay: CardName.UNIONPAY,
};

export const detectCardbyCardNumber = (cardNumber: string) => {
  const cardInfo = creditCardType(cardNumber);
  return cardInfo?.[0]?.type;
};

export const getCardLogoByType = (cardType: any, useTypeMapping = false) => {
  let type = cardType;
  if (useTypeMapping) {
    type = TYPE_MAPPING[cardType];
  }
  switch (type) {
    case CardName.VISA: {
      return '/images/cards/Payment_Visa.svg';
    }

    case CardName.MASTERCARD: {
      return '/images/cards/Payment_Mastercard.svg';
    }

    case CardName.AMERICAN_EXPRESS: {
      return '/images/cards/Payment_Amex.svg';
    }

    case CardName.DINERS_CLUB: {
      return '/images/cards/diners-club.svg';
    }

    case CardName.JCB: {
      return '/images/cards/jcb.svg';
    }

    case CardName.DISCOVER: {
      return '/images/cards/discover.svg';
    }

    case CardName.UNIONPAY: {
      return '/images/cards/union.svg';
    }

    default: {
      return null;
    }
  }
};

export const getCardType = (cardNumber: string) => {
  const cardType = detectCardbyCardNumber(cardNumber);
  return cardType;
};

export const getCardLogoByCardNumber = (cardNumber: string) => {
  const cardType = getCardType(cardNumber);
  return getCardLogoByType(cardType);
};

export const getStripeErrorTitle = (error: any) => {
  switch (error) {
    case 'card_declined': {
      return 'CARD_DECLINED_TITLE';
    }
    case 'processing_error': {
      return 'CARD_DECLINED_TITLE';
    }
    case 'expired_card': {
      return 'CARD_DECLINED_TITLE';
    }
    case 'incorrect_number': {
      return 'CARD_DECLINED_TITLE';
    }
    case 'incorrect_cvc': {
      return 'CARD_DECLINED_TITLE';
    }
    case 'setup_intent_authentication_failure': {
      return 'CARD_DECLINED_TITLE';
    }
    default: {
      return 'SOMETHING_WENT_WRONG_TITLE_FOR_STRIPE';
    }
  }
};

export const getStripeErrorCotent = (error: any) => {
  switch (error) {
    case 'card_declined': {
      return 'CARD_DECLINED_CONTENT';
    }
    case 'processing_error': {
      return 'CARD_DECLINED_CONTENT';
    }
    case 'expired_card': {
      return 'CARD_DECLINED_CONTENT';
    }
    case 'incorrect_number': {
      return 'CARD_DECLINED_CONTENT';
    }
    case 'incorrect_cvc': {
      return 'CARD_DECLINED_CONTENT';
    }
    case 'setup_intent_authentication_failure': {
      return 'CARD_DECLINED_CONTENT';
    }
    case 'incorrect_zip': {
      return 'PAYMENT_POSTCODE_CONTENT';
    }
    default: {
      return 'SOMETHING_WENT_WRONG_CONTENT_FOR_STRIPE';
    }
  }
};

export const getAddCardErrorTitle = (error: any) => {
  switch (error) {
    case 'payment_postcode': {
      return 'PAYMENT_POSTCODE_TITLE';
    }
    default: {
      return 'SOMETHING_WENT_WRONG_TITLE_FOR_STRIPE';
    }
  }
};

export const getAddCardErrorCotent = (error: any) => {
  switch (error) {
    case 'payment_postcode': {
      return 'PAYMENT_POSTCODE_CONTENT';
    }
    default: {
      return 'SOMETHING_WENT_WRONG_CONTENT_FOR_STRIPE';
    }
  }
};
