import { getCusNameByOrderId } from '@/api/user';
import LoadingPage from '@/components/common/Loading';
import IntroPage from '@/components/page/Intro';
import userReducer from '@/redux/reducers/userReducer';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { UserType } from '@/utils/constant';
import { saveInternalTokenAuth } from '@/utils/helper';
import { clearSavedInternalToken } from '@/utils/internalAuth';
import { useCallback, useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const AdditionDriverIntroPage = () => {
  const [name, setName] = useState<string | undefined>('');
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state: any) => state.loading);
  const { orderID } = useParams();
  const getCustomerName = useCallback(async () => {
    dispatch({ type: 'SHOW_LOADING' });
    await saveInternalTokenAuth();
    const customerInfo = await getCusNameByOrderId(orderID);
    const customerName = customerInfo?.first_name;
    setName(customerName);
    dispatch(userReducer.actions.updateOrderId(orderID));
    dispatch({ type: 'HIDE_LOADING' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useLayoutEffect(() => {
    getCustomerName();
    return () => {
      clearSavedInternalToken();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main>
      <IntroPage userType={UserType.ADDITIONAL_DRIVER} name={name} />
      {isLoading && <LoadingPage />}
    </main>
  );
};
export default AdditionDriverIntroPage;
