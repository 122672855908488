import { getCardLogoByType } from '@/utils/cards';
import { CardInfo, CardItemWrapper, CardLogo, CardNumber, ExpiredCard } from './styled';

const CardItem: React.FC<any> = ({ card }) => {
  const logo = getCardLogoByType(card?.type, true);
  const expiryDate = 'EXP ' + card?.month + '/' + card?.year?.toString().slice(2);
  return (
    <CardItemWrapper>
      <CardInfo>
        <CardLogo src={logo} width={38} height={24} />
        <CardNumber>
          •••• <span className="fs-mask">{card?.number}</span>
        </CardNumber>
      </CardInfo>
      <ExpiredCard>
        <span className="fs-mask">{expiryDate}</span>
      </ExpiredCard>
    </CardItemWrapper>
  );
};
export default CardItem;
