import { SuccessPopupWrapper, SuccessPopupMessageWrapper, SuccessPopupIconWrapper } from './SuccessPopupStyled';
const SuccessPopup = ({ message }: any) => {
  return (
    <>
      <SuccessPopupWrapper className='fs-unmask'>
        <SuccessPopupIconWrapper>
          <img
            style={{ width: '20px', height: '20px', cursor: 'pointer' }}
            src="/images/icon/Icon_success.svg"
            alt=""
          />
        </SuccessPopupIconWrapper>
        <SuccessPopupMessageWrapper className='fs-unmask'>{message}</SuccessPopupMessageWrapper>
      </SuccessPopupWrapper>
    </>
  );
};

export default SuccessPopup;
