const initialState = false;

export const loadingReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'SHOW_LOADING':
      return true;
    case 'HIDE_LOADING': {
      return false;
    }
    default:
      return state;
  }
};
