import { styled } from '@mui/material';

export const ContentWrapper = styled('div', { name: 'ContentWrapper' })(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  flex: '1 0',
  [theme.breakpoints.down('lg')]: {
    justifyContent: 'space-between',
  },
}));
