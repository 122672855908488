import { styled } from '@mui/material';

export const MessageWrapper = styled('div')(({ theme }) => ({
  paddingTop: 16,
  textAlign: 'center',
  fontFamily: 'FuturaLTPro-Medium',
  fontSize: '16px',
  fontWeight: 'normal',
  lineHeight: 1.5,
  letterSpacing: '1px',
  [theme.breakpoints.up('sm')]: {
    paddingRight: 200,
    paddingLeft: 200
  },
  [theme.breakpoints.down('sm')]: {
    paddingRight: 40,
    paddingLeft: 40
  },
}));

export const NoCardWrapper = styled('div')(({ theme }) => ({
  height: 296,
  backgroundColor: '#0f0f0f',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginTop: 32,
  marginBottom: 32,
  [theme.breakpoints.down('sm')]: {
    marginTop: 24,
    marginBottom: 24,
  },
}));
