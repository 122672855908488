import { getInternalToken } from '@/api/auth';
import { sha256 } from 'js-sha256';
import moment from 'moment';
import React from 'react';
import { UserType } from './constant';
import { saveInternalToken } from './internalAuth';

export const genSecretTokenInternalAuth = () => {
  const clientId = process.env.REACT_APP_INTERNAL_AUTH_CLIENT_ID || '';
  const secret_token = process.env.REACT_APP_INTERNAL_AUTH_SECRET_TOKEN || '';
  const currentTimeStamp = moment.utc().valueOf();
  const hashValue = sha256(clientId + secret_token + currentTimeStamp) + ':' + currentTimeStamp;
  return hashValue;
};

export const saveInternalTokenAuth = async () => {
  const secretToken = genSecretTokenInternalAuth();
  const payload = {
    client_id: process.env.REACT_APP_INTERNAL_AUTH_CLIENT_ID || '',
    secret_token: secretToken,
  };
  try {
    const token = await getInternalToken(payload);
    saveInternalToken(token);
  } catch (err) {
    //need to define popup or logging to tracking.
  }
};

export const useOnClickOutside = (ref: any, handler: any) => {
  React.useEffect(() => {
    const listener = (event: Event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

export const endUrlForStep = (userType: number, currentStep: number, isAddPaymentMethodForCP?: boolean) => {
  if (userType === UserType.RENTAL_CARS) {
    switch (currentStep) {
      case 0:
        return '/register';
      case 1:
        return '/verification-phone';
      case 2:
        return '/verification-identity';
      case 3:
        return '/insurance-declaration';
      case 4:
        return '/deposit-payment';
      default:
        return '/';
    }
  } 
  else if (isAddPaymentMethodForCP && userType === UserType.CONCIERGE_PORTAL){
    switch (currentStep) {
      case 0:
        return '/register';
      case 1:
        return '/verification-phone';
      case 2:
        return '/verification-identity';
      case 3:
        return '/insurance-declaration';
      case 4:
        return '/payment-method';
      default:
        return '/';
    }
  }
  else if (userType === UserType.ADDITIONAL_DRIVER || userType === UserType.CONCIERGE_PORTAL) {
    switch (currentStep) {
      case 0:
        return '/register';
      case 1:
        return '/verification-phone';
      case 2:
        return '/verification-identity';
      case 3:
        return '/insurance-declaration';
      default:
        return '/';
    }
  } else {
    return '/';
  }
};

export const getTextAddress = (location: Record<string, any>) => {
  if (!location) {
    return '';
  }
  let textAddress = '';
  let loc = location;
  const value = [];
  if (loc.line1 && loc.line1.trim()) {
    textAddress += `, ${loc.line1}`;
    value.push(loc.line1);
  }
  if (loc.line2 && loc.line2.trim()) {
    textAddress += `, ${loc.line2}`;
    value.push(loc.line2);
  }
  if (loc.city && loc.city.trim()) {
    textAddress += `, ${loc.city}`;
    value.push(loc.city);
  }
  if (loc.postal_code && loc.postal_code.trim()) {
    textAddress += `, ${loc.postal_code}`;
    value.push(loc.postal_code);
  }

  if (loc.country && loc.country.trim()) {
    if (loc.country === 'UK') {
      const locRe = {
        country: 'United Kingdom',
      };
      loc = { ...loc, ...locRe };
    }
    textAddress += `, ${loc.country}`;
    value.push(loc.country);
  }

  return {
    text: textAddress.replace(/(^,\s*)|(,\s*$)/gi, ''),
    value,
  };
};

export const getUserTypeNumber = (userType: UserType) => {
  switch (userType) {
    case UserType.ADDITIONAL_DRIVER:
      return 6;
    case UserType.RENTAL_CARS:
      return 4;
    case UserType.CONCIERGE_PORTAL:
      return 5;
    default:
      return null;
  }
};

export const UrlExists = (url: string) => {
  var http = new XMLHttpRequest();
  http.open('HEAD', url, false);
  http.send();
  return http.status !== 404;
};

export const getPaymentMethodLabelForSideBar = (userType: UserType, isAddPaymentMethodForCP: boolean): string => {
  if(userType === UserType.RENTAL_CARS){
    return 'DEPOSIT_PAYMENT'
  }else if(userType === UserType.CONCIERGE_PORTAL && isAddPaymentMethodForCP){
    return 'PAYMENT_METHOD'
  }
  return '';
};

export const getExpireDate = (month?: string, year?: number | string) => {
  if (!month || !year) return '';

  if (month.length < 2) {
    return '0' + month + '/' + year.toString().slice(-2);
  }
  return month + '/' + year.toString().slice(-2);
};

export const getErrorTitleOfRemoveCreditCard = (error: string) => {
  switch (error) {
    case 'StillHaveOrderInProcess': {
      return 'STILL_HAVE_ORDER_IN_PROCESS_TITLE';
    }
    default: {
      return 'SOMETHING_WENT_WRONG_TITLE';
    }
  }
};

export const getErrorContentOfRemoveCreditCard = (error: string) => {
  switch (error) {
    case 'StillHaveOrderInProcess': {
      return 'STILL_HAVE_ORDER_IN_PROCESS_CONTENT';
    }
    default: {
      return 'SOMETHING_WENT_WRONG_CONTENT';
    }
  }
};