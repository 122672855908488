import Logo from '@/components/common/Logo';
import { Content, Divider } from '@/styles/common';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SubTitle, Title, Wrapper } from './styled';

const Page404: React.FC<any> = () => {
  const { t } = useTranslation();

  return (
    <Wrapper className="fs-unmask">
      <Content>
        <Logo />
        <Divider />
        <Title variant="title" color="primary">
          {t('404_PAGE')}
        </Title>
        <SubTitle variant="content" color="secondary">
          {t('404_PAGE_DETAIL')}
        </SubTitle>
      </Content>
    </Wrapper>
  );
};
export default Page404;
