import { createSlice } from '@reduxjs/toolkit';

export default createSlice({
  name: 'error',
  initialState: {
    title: null,
    content: null,
  },
  reducers: {
    setError: (state, action) => {
      state.title = action.payload.title;
      state.content = action.payload.content;
    },
  },
});
