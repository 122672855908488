import { refreshTokenApi } from '@/api/auth';
import { clearSavedToken, getAccessToken, saveToken } from '@/utils/auth';
import axios from 'axios';
import Cookies from 'js-cookie';

const authHttpClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 30 * 1000,
  headers: { accept: 'application/json' },
});

authHttpClient.interceptors.request.use(
  (req: any) => {
    req.headers['X-Auth-Token'] = getAccessToken();
    return req;
  },
  (error) => Promise.reject(error),
);

authHttpClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalConfig = error.config;
    if (error.response?.status === 401 && !originalConfig._retry) {
      originalConfig._retry = true;
      const refreshToken = Cookies.get('refreshToken');
      if (!refreshToken) {
        clearSavedToken();
        Cookies.remove('refreshToken');
      }
      const data = await refreshTokenApi(refreshToken);
      const { access_token } = data;
      if (access_token) saveToken(access_token);
      return authHttpClient(originalConfig);
    }

    return Promise.reject(error.response.data);
  },
);
export default authHttpClient;
