import { Logo, LogoWrapper } from './styles';

type Props = {
  theme?: string;
  width?: string | Record<string, string>;
  logo?: string;
};

const TheOutLogo = ({ theme = 'dark', width = '160', logo = 'theout' }: Props) => {
  const prefixSrc = theme === 'light' ? 'white_' : '';
  const logoBrand =
    logo === 'theout' ? (
      <>
        <img src={`/images/logo/${prefixSrc}${logo}.svg`} alt="" />
        <img style={{ marginTop: '10px' }} src={`/images/logo/${prefixSrc}${logo}_sub.svg`} alt="" />
      </>
    ) : (
      <img src={`/images/logo/${prefixSrc}${logo}.svg`} alt="" />
    );
  return (
    <LogoWrapper width={width}>
      <Logo>{logoBrand}</Logo>
    </LogoWrapper>
  );
};
export default TheOutLogo;
