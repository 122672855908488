import { Button, Text } from '@/components/common';
import { styled } from '@mui/material';
export const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const WrapperText = styled('div')(({ theme }) => ({
  textAlign: 'center',
}));

export const ModalButton = styled(Button)(({ theme }) => ({
  border: 'solid 1px #000',
  boxShadow: 'unset',
  marginTop: '30px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginTop: '40px',
  },
  '&:hover': {
    boxShadow: 'unset'
  },
}));

export const ContactUsButton = styled(Button)(({ theme }) => ({
  border: 'solid 1px #000',
  boxShadow: 'unset',
  marginTop: '16px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
  '&:hover': {
    boxShadow: 'unset'
  },
}));

export const ModalHeader = styled(Text)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: 14,
  marginBottom: 0,
  [theme.breakpoints.down('sm')]: {
    marginTop: 24,
  },
}));

export const TitleWrapper = styled(Text)(({ theme }) => ({
  marginTop: 16,
  marginBottom: 16,
  letterSpacing: 3,
  paddingLeft: 70,
  paddingRight: 70,
  fontSize: '18px',
  lineHeight: '28px',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: 35,
    paddingRight: 35,
  },
}));

export const ContentWrapper = styled(Text)(({ theme }) => ({
  letterSpacing: 1,
  color: '#555',
  fontSize: '15px',
  lineHeight: '24px',
  paddingLeft: 80,
  paddingRight: 80,
  [theme.breakpoints.down('sm')]: {
    paddingLeft: 40,
    paddingRight: 40,
  },
}));

export const BodyWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
}));
