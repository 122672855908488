import { useOnClickOutside } from '@/utils/helper';
import { InputAdornment, styled } from '@mui/material';
import * as React from 'react';
import { StyledTextField } from '../TextField';
import countries from './countries';
import { Flag } from './Flag';

export const CodeField = styled(StyledTextField, {
  shouldForwardProp: (propName: string) => propName !== 'disable',
})<{ disable: boolean }>(({ theme, disable }) => ({
  marginRight: '10px',
  width: '69px',
  cursor: 'pointer !important',
  input: {
    cursor: 'pointer !important',
  },
  '& .MuiInputBase-root ': {
    cursor: 'pointer !important',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#272727',
    transform: 'none',
    borderBottom: '1px solid #272727',
  },
  '& .MuiInput-underline:before': {
    borderBottomColor: '#272727',
  },
}));

export const StartAdornment = styled('div')(({ theme }) => ({
  color: '#fff',
  height: '48px',
  lineHeight: '48px',
}));

export const Icon = styled('img')(({ theme }) => ({
  cursor: 'pointer',
  width: '16px',
  height: '16px',
}));

export const Wrapper = styled('div')(({ theme }) => ({
  position: 'relative',
}));

export const DropBox = styled('div')(({ theme }) => ({
  padding: '0px 8px',
  border: 'solid 1px #272727',
  position: 'absolute',
  top: '56px',
  left: '0',
  zIndex: '1000',
  backgroundColor: '#000',
  display: 'block',
  overflowX: 'hidden',
  width: '360px',
  height: '200px',
}));

export const DropList = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '62px',
  overflowY: 'scroll',
  maxHeight: 'calc(100% - 70px)',
  width: 'calc(100% - 10px)',
}));

export const DropItem = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '40px',
  cursor: 'pointer',
  padding: '0 8px',
  '&:hover': {
    backgroundColor: '#dd8fe5',
  },
}));

export const FlagNameWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '40px',
}));

export const CountryName = styled('div')(({ theme }) => ({
  color: '#fff',
  fontSize: '14px',
  fontFamily: 'FuturaLTPro-Book',
  alignItems: 'center',
  lineHeight: '40px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '230px',
}));

export const CountryDial = styled('div')(({ theme }) => ({
  color: '#fff',
  fontSize: '14px',
  fontFamily: 'FuturaLTPro-Book',
  alignItems: 'center',
  lineHeight: '40px',
  width: '40px',
  textAlign: 'right',
}));

const CountryCodeSelect: React.FC<any> = ({ defaultValue = '44', value, onChange }) => {
  const [open, setOpen] = React.useState(false);
  const [searchKey, setSearchKey] = React.useState('');
  const [searchValue, setSearchValue] = React.useState('');
  const [currentValue, setCurrentValue] = React.useState(value || defaultValue || '');

  const inputContainerRef = React.createRef();
  useOnClickOutside(inputContainerRef, () => setOpen(false));

  const handleChange = (evt: React.ChangeEvent<{ value: string }>) => {
    setSearchKey(evt.target.value.toLowerCase());
    setSearchValue(evt.target.value);
  };

  const onSelectOption = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();

    const chosenValue = evt.target.getAttribute('data-value');

    setCurrentValue(chosenValue);

    if (typeof onChange === 'function') {
      onChange(chosenValue);
    }
    setOpen(false);
  };

  return (
    <Wrapper ref={inputContainerRef}>
      <CodeField
        onClick={() => setOpen((prev) => !prev)}
        variant="standard"
        value={currentValue}
        InputProps={{
          'data-testid': 'country-code-picker',
          readOnly: true,
          startAdornment: (
            <InputAdornment style={{ marginRight: '1px' }} position="start">
              <StartAdornment>+</StartAdornment>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {open ? <Icon src="/images/dropdown/up.png" alt="" /> : <Icon src="/images/dropdown/down.png" alt="" />}
            </InputAdornment>
          ),
        }}
      />
      {open ? (
        <DropBox className="open">
          <StyledTextField
            fullWidth
            value={searchValue}
            onChange={handleChange}
            variant="standard"
            autoFocus
            InputProps={{
              'data-testid': 'country-code-search',
              startAdornment: (
                <InputAdornment position="start">
                  <Icon src="/images/dropdown/search.svg" alt="" />
                </InputAdornment>
              ),
            }}
          />
          <DropList>
            {countries
              .filter(
                (country) => country.name.toLowerCase().includes(searchKey) || country.dialCode.includes(searchKey),
              )
              .map((item, index) => {
                return (
                  <DropItem key={index} onMouseDown={onSelectOption} data-value={item.dialCode}>
                    <FlagNameWrapper>
                      <Flag className={item.iso2} data-value={item.dialCode} />
                      <CountryName data-value={item.dialCode}>{item.name}</CountryName>
                    </FlagNameWrapper>
                    <CountryDial data-value={item.dialCode}>{item.dialCode}</CountryDial>
                  </DropItem>
                );
              })}
          </DropList>
        </DropBox>
      ) : null}
    </Wrapper>
  );
};

export default CountryCodeSelect;
