import { styled } from '@mui/material/styles';

export const Homepage = styled('div')(({ theme }) => ({
  backgroundRepeat: 'no-repeat',
  height: '100vh',
  width: '100vw',
  backgroundColor: '#dddcd8',
  backgroundSize: 'contain',
  backgroundPosition: 'bottom',
  minHeight: '800px',
  backgroundImage: 'url(https://static.theout.com/checkin/homepage/checkin-375.jpg)',
  [theme.breakpoints.up('sm')]: {
    backgroundImage: 'url(https://static.theout.com/checkin/homepage/checkin-540.jpg)',
    minHeight: '1000px',
  },
  [theme.breakpoints.up('md')]: {
    backgroundImage: 'url(https://static.theout.com/checkin/homepage/checkin-768.jpg)',
    minHeight: '1050px',
  },
  [theme.breakpoints.up('1000')]: {
    backgroundImage: 'url(https://static.theout.com/checkin/homepage/checkin-768.jpg)',
    minHeight: '1100px',
  },
  [theme.breakpoints.up('lg')]: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: 'url(https://static.theout.com/checkin/homepage/checkin-1024.jpg)',
    minHeight: '700px',
  },
  [theme.breakpoints.up('xl')]: {
    backgroundImage: 'url(https://static.theout.com/checkin/homepage/checkin-1280.jpg)',
  },
  [theme.breakpoints.up('xxl')]: {
    backgroundImage: 'url(https://static.theout.com/checkin/homepage/checkin-1440.jpg)',
  },
  [theme.breakpoints.up('xxxl')]: {
    backgroundImage: 'url(https://static.theout.com/checkin/homepage/checkin-1920.jpg)',
  },
}));

export const ErrorMessage = styled('p')(() => ({
  marginTop: 24,
  color: '#e54155',
}));

export const CheckboxTermWrapper = styled('div')(({ theme }) => ({
  marginTop: 16,
  [theme.breakpoints.up('sm')]: {
    marginTop: 24,
  },
}));
