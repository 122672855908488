import dayjs from 'dayjs';

const checkDate = (str: string, max: number) => {
  if (str.charAt(0) !== '0' || str === '00') {
    var num = parseInt(str);
    if (isNaN(num) || num <= 0 || num > max) num = 12;
    str = num > parseInt(max.toString().charAt(0)) && num.toString().length === 1 ? '0' + num : num.toString();
  }
  return str;
};

export const convertStringToDate = (value: string) => {
  if (!value) return '';
  if (/\D\/$/.test(value)) value = value.substr(0, value.length - 1);
  var values = value.split('/').map(function (v) {
    return v.replace(/\D/g, '');
  });
  if (values[0]) values[0] = checkDate(values[0], 31);
  if (values[1]) values[1] = checkDate(values[1], 12);
  var output = values.map(function (v, i) {
    return v.length === 2 && i < 2 ? v + ' / ' : value.length >= 9 ? v + ' / ' : v;
  });
  const date = output.join('').substr(0, 14).replaceAll(' ', '');

  return date;
};

//convert DD/MM/YYYY => YYYY-MM-DD
export const covertDateBeforeSubmit = (date: string) => {
  const dateConverted = dayjs(date, 'DD/MM/YYYY');
  return dateConverted.isValid() ? `${dateConverted.year()}-${dateConverted.month() + 1}-${dateConverted.date()}` : '';
};
