import { styled } from '@mui/system';

export const Title = styled('div')(({ theme }) => ({
  fontWeight: '500',
  lineHeight: '1.2',
  letterSpacing: '3px',
  fontFamily: 'FuturaLTPro-Medium',
  fontSize: '32px',
  color: '#fff',
  textTransform: 'uppercase',
}));

export const Paragraph = styled('div')(({ theme }) => ({
  display: 'flex',
}));

export const Icon = styled('img')(({ theme }) => ({
  marginRight: '16px',
}));
