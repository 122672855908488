import { authHttpClient } from '@/libs/axios';

let cachedData: Record<any, any> = {};

export const getPostcode = async (postcode: string) => {
  try {
    if (!cachedData[postcode]) {
      const response = await authHttpClient.get('/address', { params: { postal_code: postcode } });
      const data: Record<any, any>[] = await response.data.allowed;
      cachedData[postcode] = data;
      return data;
    }
    return cachedData[postcode];
  } catch (error: any) {
    throw error.error;
    //define logging
  }
};
