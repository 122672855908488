import { combineReducers, configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import authReducer from './reducers/authReducer';
import { loadingReducer } from './reducers/loadingReducer';
import { sidebarReducer } from './reducers/sidebarStatus';
import userReducer from './reducers/userReducer';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import errorReducer from './reducers/errorReducer';

const reducers = combineReducers({
  auth: authReducer.reducer,
  user: userReducer.reducer,
  loading: loadingReducer,
  sidebar: sidebarReducer,
  error: errorReducer.reducer,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'user', 'sidebar'],
};
const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});
export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
