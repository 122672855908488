import { ImgWrapper, InnerWrapper, LoadingWrapper } from './LoadingStyled';
const LoadingPage = () => {
  return (
    <LoadingWrapper>
      <InnerWrapper>
        <ImgWrapper src="/images/logo/circle-logo-white.png" alt="Indicator" fetchPriority={'high'} />
      </InnerWrapper>
    </LoadingWrapper>
  );
};
export default LoadingPage;
