import { Button, Text } from '@/components/common';
import { styled } from '@mui/material';

export const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const WrapperTitle = styled(Text)(({ theme }) => ({
  textAlign: 'center',
  marginTop: 16,
  marginBottom: 16,
  width: 320,
  [theme.breakpoints.down('sm')]: {
    width: 263,
  },
}));

export const WrapperMessage = styled(Text)(({ theme }) => ({
  textAlign: 'center',
  width: 320,
  lineHeight: 1.6,
  [theme.breakpoints.down('sm')]: {
    width: 263,
  },
}));

export const ModalButton = styled(Button)(({ theme }) => ({
  width: '100%',
  marginTop: 64,
  boxShadow: 'none',
  [theme.breakpoints.down('sm')]: {
    marginTop: 24,
  },
}));

export const ModalHeader = styled('div')(({ theme }) => ({
  width: '100%',
  textAlign: 'center',
  paddingTop: 40,
  lineHeight: 0,
  [theme.breakpoints.down('sm')]: {
    paddingTop: 8,
  },
}));

export const WarningIcon = styled('img')({
  width: 32,
  height: 32,
});
