//@ts-nocheck
import { useEffect } from 'react';
import { DivOverlay, OverlayWrapper, Wrapper, WrapperContent } from './styled';

const Overlay = (props) => {
  const clssName = 'modal-open';
  const { noDisableScroll, className } = props;
  useEffect(() => {
    if (!noDisableScroll) {
      const header = document.getElementById('navbar');
      document.body.classList.add(clssName);
      if (header) {
        header.style.zIndex = -1;
      }
      return () => {
        document.body.classList.remove(clssName);
        if (header) {
          header.style.zIndex = '';
        }
      };
    }
  });
  return (
    <Wrapper
      className={className}
      onMouseDown={(evt) => {
        if (props.isLoading) {
          evt.preventDefault();
          evt.stopPropagation();
        }
      }}
    >
      <OverlayWrapper>
        <DivOverlay opacity={props.overlayOpacity} />
        <WrapperContent>{props.children}</WrapperContent>
      </OverlayWrapper>
    </Wrapper>
  );
};

export default Overlay;
