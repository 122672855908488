import CheckIcon from '@mui/icons-material/Check';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';

interface newCheckboxProps extends CheckboxProps {
  mode: string;
}

const BpIcon = styled('span')(({ theme }) => ({}));

export const BpCheckedIcon = styled(CheckIcon, {
  shouldForwardProp: (propName: string) => propName !== 'mode',
  name: 'CheckIcon',
})<{ mode: string }>(({ mode }) => ({
  width: 16,
  height: 16,
  color: mode === 'dark' ? '#000' : '#fff',
  padding: 2,
  backgroundColor: mode === 'dark' ? '#fff' : '#000',
  borderRadius: 2,
}));

export const BaseCheckbox = styled(Checkbox, {
  shouldForwardProp: (propName: string) => propName !== 'mode',
  name: 'CheckIcon',
})<{ mode: string }>(({ mode }) => ({
  border: `1px solid ${mode === 'dark' ? '#fff' : '#000'}`,
  borderRadius: 2,
  padding: 0,
  width: 16,
  height: 16,
}));

export default function StyledCheckbox(props: newCheckboxProps) {
  return (
    <BaseCheckbox
      disableRipple
      checkedIcon={<BpCheckedIcon mode={props.mode} />}
      icon={<BpIcon mode={props.mode} />}
      {...props}
    />
  );
}
