import { authHttpClient } from '@/libs/axios';

export const getClientSecretStripe = async (uid: string | undefined): Promise<any> => {
  try {
    const response = await authHttpClient.post(`users/${uid}/credit_cards/setup`);
    const data = await response.data;
    return data;
  } catch (error: any) {
    throw error.error;
  }
};

export const addCreditCard = async (uid: string | undefined, payload: any): Promise<any> => {
  try {
    const response = await authHttpClient.post(`users/${uid}/credit_cards`, payload);
    const data = await response.data;
    return data;
  } catch (error: any) {
    throw error.error;
  }
};

export const removeCreditCard = async (uid: string | undefined, cardNumber: any): Promise<any> => {
  try {
    const response = await authHttpClient.delete(`users/${uid}/credit_cards/${cardNumber}`);
    const data = await response.data;
    return data;
  } catch (error: any) {
    throw error.error;
  }
};
