import { getCountries } from '@/api/countries';
import { useOnClickOutside } from '@/utils/helper';
import { InputAdornment, styled } from '@mui/material';
import { useField } from 'formik';
import * as React from 'react';
import FormLabel from '../Label';
import countries from '../PhoneField/countries';
import { StyledTextField } from '../TextField';
import { Flag } from './Flag';

export const CodeField = styled(StyledTextField, {
  shouldForwardProp: (propName: string) => propName !== 'disable',
})<{ disable: boolean }>(({ theme, disable }) => ({
  marginRight: '10px',
  cursor: 'pointer',
  input: {
    cursor: 'pointer',
    caretColor: 'transparent',
  },
}));

export const StartAdornment = styled('div')(({ theme }) => ({
  color: '#fff',
  height: '48px',
  lineHeight: '48px',
}));

export const Icon = styled('img')(({ theme }) => ({
  cursor: 'pointer',
  width: '16px',
  height: '16px',
}));

export const Wrapper = styled('div')(({ theme }) => ({
  position: 'relative',
}));

export const DropBox = styled('div')(({ theme }) => ({
  padding: '0px 8px',
  border: 'solid 1px #272727',
  position: 'absolute',
  top: '65px',
  left: '0',
  zIndex: '1000',
  backgroundColor: '#000',
  display: 'block',
  overflowX: 'hidden',
  width: '100%',
  height: '220px',
}));

export const DropList = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '65px',
  overflowY: 'scroll',
  maxHeight: 'calc(100% - 70px)',
  width: 'calc(100% - 10px)',
}));

export const DropItem = styled('div', {
  shouldForwardProp: (propName: string) => propName !== 'active',
})<{ active: boolean }>(({ active }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '40px',
  cursor: 'pointer',
  padding: '0 8px',
  backgroundColor: active ? '#dd8fe5' : '#000',
  '&:hover': {
    backgroundColor: '#dd8fe5',
  },
}));

export const FlagNameWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '40px',
}));

export const CountryName = styled('div')(({ theme }) => ({
  color: '#fff',
  fontSize: '14px',
  fontFamily: 'FuturaLTPro-Book',
  alignItems: 'center',
  lineHeight: '40px',
}));

const CountryPickerField = ({ config, name, formik, initialValues, clearValue }: any) => {
  const [field, meta, { setValue }] = useField(name ?? 'TextField');
  const [open, setOpen] = React.useState(false);
  const [searchKey, setSearchKey] = React.useState('');
  const [countryName, setCountryName] = React.useState('');
  const getData = async () => {
    return await getCountries();
  };

  const {
    label,
    disabled,
    autoComplete = 'false',
    placeholder,
    variant = 'standard',
    margin,
    fullWidth = true,
    rows,
    size,
    required,
    autoFocus,
    readOnly,
    maxLength,
  } = config;

  const haveError: boolean = !!(meta.error && (meta.touched || formik.submitCount));

  const props = {
    ...field,
    disabled: disabled,
    required,
    autoFocus,
    variant,
    size,
    inputProps: {
      maxLength,
      autoComplete,
      readOnly,
    },
    InputLabelProps: {
      shrink: true,
    },
    rows,
    placeholder,
    margin,
    error: haveError,
    fullWidth,
  };

  const inputContainerRef = React.createRef();

  useOnClickOutside(inputContainerRef, () => setOpen(false));

  const handleChange = (evt: React.ChangeEvent<{ value: string }>) => {
    setSearchKey(evt.target.value.toLowerCase());
  };

  React.useEffect(() => {
    getData().then((countries) => {
      const country = countries.find((elm) => initialValues?.[name] === elm.alpha_3);
      setCountryName(country?.name ?? '');
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickInput = () => {
    setOpen((prev) => !prev);
  };

  const handleSelect = (name: string, iso2: string) => {
    clearValue && clearValue();
    getData().then((countries) => {
      const country = countries.find((elm) => name === elm.name || iso2 === elm.alpha_2.toLowerCase());
      setValue(country?.alpha_3 ?? country?.alpha_2);
      setCountryName(name);
    });
    setOpen(false);
  };

  return (
    <>
      <FormLabel text={label} />
      <Wrapper ref={inputContainerRef}>
        <CodeField
          {...props}
          value={countryName}
          fullWidth
          onClick={() => onClickInput()}
          variant="standard"
          onChange={(evt: React.ChangeEvent) => {
            evt.preventDefault();
            return;
          }}
          InputProps={{
            'data-testid': 'country-picker',
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                {open ? <Icon src="/images/dropdown/up.png" alt="" /> : <Icon src="/images/dropdown/down.png" alt="" />}
              </InputAdornment>
            ),
          }}
        />
        {open ? (
          <DropBox className="open fs-mask">
            <StyledTextField
              fullWidth
              onChange={handleChange}
              variant="standard"
              autoFocus
              value={searchKey}
              InputProps={{
                'data-testid': 'country-search',
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon src="/images/dropdown/search.svg" alt="" />
                  </InputAdornment>
                ),
              }}
            />
            <DropList>
              {countries
                .filter((country) => country.name.toLowerCase().includes(searchKey))
                .map((item, index) => {
                  return (
                    <DropItem
                      key={index}
                      active={countryName === item.name}
                      onClick={() => handleSelect(item.name, item.iso2)}
                    >
                      <FlagNameWrapper>
                        <Flag className={`${item.iso2} fs-exclude`} data-value={item.dialCode} />
                        <CountryName>{item.name}</CountryName>
                      </FlagNameWrapper>
                    </DropItem>
                  );
                })}
            </DropList>
          </DropBox>
        ) : null}
      </Wrapper>
    </>
  );
};

export default CountryPickerField;
