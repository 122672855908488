import { Button, StyledForm, Text } from '@/components/common';
import { FormMarginDense, FormWrapper } from '@/components/form';
import PendingKycModal from '@/components/modal/PendingKYCModal';
import RejectedKycModal from '@/components/modal/RejectedKYCModal';
import CheckboxTerm from '@/components/page/Register/CheckboxTerm';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { ModalRenderer } from '@/utils/modalUtils';
import { styled } from '@mui/material';
import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Icon, Paragraph } from './styles';
import { useUser } from '../../hook/useUser';
import { get } from 'lodash';
import { UserType } from '@/utils/constant';
import { verifyAdditionalDriver } from '@/api/user';
import AdditionalDriverErrorModal from '@/components/modal/AdditionalDriverErrorModal';
import SomethingWentWrongModal from '@/components/modal/SomethingWentWrongModal';

export const Divider = styled('div')({
  margin: '32px 0px',
  height: '1px',
  backgroundColor: '#191919',
  width: '100%',
});

const InsuranceDeclarationPage: React.FC<any> = () => {
  const [checkAgree, setCheckAgree] = React.useState(false);
  const navigate = useNavigate();
  const userType = useAppSelector((state: any) => state.user.userType);
  const orderId = useAppSelector((state: any) => state.user.orderId);
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state: any) => state.auth.userId);
  const [reloadUser] = useUser(userId);
  const isAddPaymentMethodForCP = useAppSelector((state: any) => state.user.isAddPaymentMethod);

  const isPendingKYC = (user: any) => {
    const kycRequired = get(user, ['insurance', 'requires'], []);
    const verification_status = get(user, ['verification_status'], null);

    return (
      verification_status === 'manual_verification' ||
      (kycRequired.length === 1 && kycRequired?.includes('User.selfie-in-verification'))
    );
  };

  useEffect(() => {
    dispatch({ type: 'HIDE_LOADING' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleVerifyAdditionalDriver = async () => {
    try {
      const user = await reloadUser();
      const verification_status = get(user, ['verification_status'], null);

      if (verification_status === 'rejected') {
        dispatch({ type: 'HIDE_LOADING' });
        ModalRenderer.open({
          component: RejectedKycModal,
          theme: 'white',
          inMobile: 'bottom',
        });
        return;
      }

      try {
        await verifyAdditionalDriver(userId, orderId);
        if (isPendingKYC(user)) {
          ModalRenderer.open({
            component: PendingKycModal,
            theme: 'white',
            inMobile: 'bottom',
          });
        } else {
          navigate('/thank-you');
        }
      } catch ({ error }: any) {
        switch (error) {
          case 'age_not_allow':
          case 'issueing_not_allow':
            ModalRenderer.open({
              component: AdditionalDriverErrorModal,
              theme: 'white',
              inMobile: 'bottom',
            });
            break;
          default:
            ModalRenderer.open({
              component: SomethingWentWrongModal,
              theme: 'white',
              inMobile: 'bottom',
            });
            break;
        }
      } finally {
        dispatch({ type: 'HIDE_LOADING' });
      }
    } catch (error: any) {
      console.error('### Reload user error', error);
    }
  };

  const onSubmit = async () => {
    dispatch({ type: 'SHOW_LOADING' });
    if (userType === UserType.RENTAL_CARS || (userType === UserType.CONCIERGE_PORTAL && isAddPaymentMethodForCP)) {
      await reloadUser();
      dispatch({ type: 'UPDATE_STATUS', payload: { insurance_declaration_passed: true } });
      dispatch({ type: 'NEXT_STEP' });
      dispatch({ type: 'HIDE_LOADING' });
      return;
    }
    await handleVerifyAdditionalDriver();
  };
  const { t } = useTranslation();
  const content =
    userType === UserType.ADDITIONAL_DRIVER
      ? t('INSURANCE_DECLARATION_MODAL_AD').split('\n')
      : t('INSURANCE_DECLARATION_MODAL').split('\n');
  const max = content.length;

  return (
    <FormWrapper>
      <Text color="darkPrimary" variant="title">
        {t('INSURANCE_DECLARATION')}
      </Text>
      <Text color="darkSecondary" variant="content">
        {content[0]}
      </Text>
      {content.map((ele, index) => {
        if (index > 0 && index < max - 2) {
          let src = ele.split('|')[0];
          let txt = ele.split('|')[1];
          return (
            <FormMarginDense key={index}>
              <Paragraph>
                <Icon src={src} width="20px" height="20px" alt="" />
                <Text color="darkSecondary" variant="content">
                  {txt}
                </Text>
              </Paragraph>
            </FormMarginDense>
          );
        }
        return null;
      })}

      <FormMarginDense>
        <Text style={{ opacity: 0.6 }} color="darkSecondary" variant="content">
          {content[max - 2]}
        </Text>
      </FormMarginDense>

      <Divider />

      <Formik initialValues={{}} onSubmit={onSubmit}>
        {(props) => (
          <StyledForm>
            <div>
              <CheckboxTerm
                onClick={() => {
                  setCheckAgree((prev) => !prev);
                }}
                testId="test_checkbox_terms"
                checked={checkAgree}
                text={t('AGREE_TO_THESE_TERMS')}
              />
              <FormMarginDense>
                <Button disabled={!checkAgree} fullWidth variant="contained" color="white" type="submit">
                  {t('CONTINUE')}
                </Button>
              </FormMarginDense>
            </div>
          </StyledForm>
        )}
      </Formik>
    </FormWrapper>
  );
};

export default InsuranceDeclarationPage;
