import { toast } from 'react-toastify';
import SuccessPopup from '@/components/common/SuccessPopup';

const notify = (msg: string) => {
  toast(<SuccessPopup message={msg} />, {
    hideProgressBar: true,
    closeOnClick: false,
  });
};

export { notify };
