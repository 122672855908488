import { styled } from '@mui/material';

export const CardItemWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  backgroundColor: '#f5f5f5',
  padding: '16px 24px',
  cursor: 'pointer',
  marginBottom: 24,
}));
export const CardInfo = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));
export const CardNumber = styled('div')(({ theme }) => ({
  display: 'inline-block',
  fontFamily: 'FuturaLTPro-Book',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '16px',
  letterSpacing: '1px',
  color: '#000',
  marginLeft: 16,
}));
export const CardLogo = styled('img')(({ theme }) => ({
  display: 'inline-block',
}));
export const ExpiredCard = styled('div')(({ theme }) => ({
  color: '#999',
  fontSize: '14px',
  lineHeight: '16px',
}));
