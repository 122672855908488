import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';

export const IntroLayout = () => {
  return (
    <div className="fs-unmask">
      <Helmet>
        <meta name="theme-color" content="#dddcd8" />
        <style>{'body { background-color: #dddcd8 !important; }'}</style>
      </Helmet>
      <Outlet />
    </div>
  );
};
