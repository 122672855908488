import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/common.json';
import { LOKALISE_DEFAULT_LANG } from './utils/constant';

const env = process.env.REACT_APP_LOKALISE_ENV;
const lang = LOKALISE_DEFAULT_LANG;
const runTimeTranslations = async (env = 'dev', lang = 'en') => {
  try {
    const lokalisrGITUrl = `https://raw.githubusercontent.com/theoutcom/theout_locales/main-ck/${env}/${lang}/common.json`;
    const response = await fetch(lokalisrGITUrl);
    const lokaliseJsonText = await response.json();
    i18n.addResourceBundle(lang, 'translation', lokaliseJsonText, true, true);
  } catch (err) {}
};

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: translationEN,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

runTimeTranslations(env, lang);

export default i18n;
