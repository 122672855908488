import CardItem from './CardItem';
import NoCard from './NoCard';
import { PaymentWrapper } from './styled';

const CardListForCP: React.FC<any> = ({ cardList }) => {
  return (
    <>
      {cardList?.length > 0 ? (

        <PaymentWrapper>
          {cardList?.map((card: any, index: number) => (
            <CardItem key={index} card={card} />
          ))}
        </PaymentWrapper>
      ) : (<NoCard />)}
    </>
  );
};
export default CardListForCP;
