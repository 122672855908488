import { Button } from '@/components/common';
import { styled } from '@mui/material';

export const ContinueBtn = styled(Button)(({ theme }) => ({
  marginTop: 24,
}));
export const DepositPaymentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  flex: '1 0',
  [theme.breakpoints.up('sm')]: {
    flex: 0,
  },
}));
export const AddPaymentMethodBtnForCP = styled(Button)(({ theme }) => ({
  marginTop: 16,
  backgroundColor: 'transparent',
  border: 'solid 1px #fff',
  color: '#fff',
  '&:hover': {
    backgroundColor: 'transparent',
    border: 'solid 1px #fff',
    color: '#fff',
  },
}));

export const ContinueBtnForCP = styled(Button)(({ theme }) => ({
  marginTop: 0,
}));

export const PaymentMethodSubTitle = styled('div')(({ theme }) => ({
  fontSize: '16px',
  lineHeight: '24px',
  color: '#999999',
  letterSpacing: 1
}));