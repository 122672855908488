import { Text } from '@/components/common';
import { styled } from '@mui/material';

export const ModalHeader = styled(Text)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: 16,
  lineHeight: '28px',
  letterSpacing: 3,
  marginBottom: 16,
  [theme.breakpoints.up('sm')]: {
    fontSize: 18,
  },
}));
export const PaymentTitle = styled('div')(({ theme }) => ({
  fontFamily: 'FuturaLTPro-Medium',
  fontSize: '11px',
  lineHeight: '20px',
  letterSpacing: '2px',
  textTransform: 'uppercase',
  fontWeight: 500,
  color: '#999',
  marginBottom: 16,
}));

export const PaymentContent = styled(Text)(({ theme }) => ({
  marginBottom: '24px',
  lineHeight: '24px',
  [theme.breakpoints.down('sm')]: {
    marginBottom: '16px',
  },
}));
