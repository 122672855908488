import Logo from '@/components/common/Logo';
import { useAppSelector } from '@/redux/hooks';
import { Content, Divider, StyledButton, SubTitle, Title, WelcomeText } from '@/styles/common';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OutroWrapper } from './styled';
import { CONTACT_EMAIL } from '@/utils/constant';

const OutroComponent: React.FC<any> = () => {
  const userInfo = useAppSelector((state: any) => state.user.userInfo);
  const { t } = useTranslation();

  const handleContactUsBtn = () => {
    window.location.href = CONTACT_EMAIL;
  };

  return (
    <OutroWrapper className="fs-unmask">
      <Content>
        <Logo />
        <Divider />
        <WelcomeText>
          {t('OUTRO_PAGE_THANKS')} <span className="fs-mask">{userInfo?.licence?.first_name}</span>
        </WelcomeText>
        <Title variant="title" color="primary">
          {t('OUTRO_PAGE_TITLE')}
        </Title>
        <SubTitle variant="content" color="secondary" outro>
          {t('OUTRO_PAGE_SUBTITLE')}
        </SubTitle>
        <StyledButton variant="contained" color={'dark'} onClick={handleContactUsBtn}>
          {t('OUTRO_PAGE_BTN')}
        </StyledButton>
      </Content>
    </OutroWrapper>
  );
};
export default OutroComponent;
