import { createSlice } from '@reduxjs/toolkit';

export default createSlice({
  name: 'auth',
  initialState: {
    userId: null,
  },
  reducers: {
    signUpSuccess: (state, action) => {
      state.userId = action.payload;
    },
    signInSuccess: (state, action) => {
      state.userId = action.payload;
    },
    clearAuth: (state) => {
      state.userId = null;
    },
  },
});
