import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { MessageWrapper, NoCardWrapper } from "./styled";

const NoCard: React.FC<any> = () => {
  const { t } = useTranslation();
  return (
    <NoCardWrapper>
      <Grid container justifyContent="center" alignItems="center">
        <img style={{ width: '40px', height: '40px' }} src="/images/icon/Icon_Menu_Payment.svg" alt="" />
      </Grid>
      <Grid container justifyContent="center" alignItems="center">
        <MessageWrapper>{t('NO_PAYMENT')}</MessageWrapper>
      </Grid>
    </NoCardWrapper>
  );
};
export default NoCard;
