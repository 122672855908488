import { styled } from '@mui/material';
import { useField } from 'formik';
import { debounce } from 'lodash';
import FormLabel from '../Label';
import * as React from 'react';
import { StyledTextField } from '../TextField';
import { useSearchPostcode } from '../../../hook/useSearchPostcode';
import { getTextAddress } from '@/utils/helper';
import { Text } from '@/components/common';
import { t } from 'i18next';
import { ErrorMessage } from '../ErrorMessage';

export const ImgWrapper = styled('img')({
  '@keyframes spin': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
  width: '60px',
  height: '60px',
  animation: `spin 2s infinite linear`,
});
export const Icon = styled('img')(({ theme }) => ({
  cursor: 'pointer',
}));

export const Wrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  marginTop: '16px',
  display: 'flex',
}));

export const LoadingWrapper = styled('div')(({ theme }) => ({
  border: 'solid 1px #272727',
  height: '195px',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  alignItems: 'center',
}));

export const DropBox = styled('div')(({ theme }) => ({
  // position: 'absolute',
  // zIndex: 1000,
  backgroundColor: '#000',
  overflow: 'overlay',
  height: '160px',
  width: '100%',
}));

export const DropItem = styled('div', {
  shouldForwardProp: (propName: string) => propName !== 'isFirst',
})<{ isFirst: boolean }>(({ theme, isFirst }) => ({
  marginTop: '8px',
  display: 'flex',
  justifyContent: 'space-between',
  // fontSize: '13px',
  // lineHeight: '16px',
  alignItems: 'center',
  padding: '0px 20px',
  height: '48px',
  color: '#fff',
  cursor: 'pointer',
  border: '1px solid #272727',
  ...(isFirst && {
    marginTop: '0px',
  }),
  '&:hover': {
    backgroundColor: '#dd8fe5'
  },
}));
export const TruncateText = styled(Text)(({ theme }) => ({
  whiteSpace: 'nowrap',
  width: '0px',
  overflow: 'hidden',
  flexGrow: 1,
  maxWidth: '80%',
  textOverflow: 'ellipsis',
}));
export const ShowManualButton = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  lineHeight: '1.6',
  letterSpacing: '1px',
  fontFamily: 'FuturaLTPro-Book',
  padding: '16px 0px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#0f0f0f',
  },
}));
const SearchPostcodeField = ({
  config,
  name,
  formik,
  setOpenManual,
  manualInput,
  selectPostcode,
  countryValue,
}: any) => {
  const [field, meta, { setValue }] = useField(name ?? 'TextField');
  const [postcode, setPostcode] = React.useState('');
  const [searched, setSearched] = React.useState(false);
  const [focus, setFocus] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const [loading, data] = useSearchPostcode(postcode);

  React.useEffect(() => {
    if (countryValue !== 'GBR') {
      setOpenManual(true);
      return;
    }
    setOpenManual(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryValue]);

  const {
    label,
    disabled,
    autoComplete = 'false',
    placeholder,
    variant = 'standard',
    margin,
    fullWidth = true,
    rows,
    size,
    required,
    autoFocus,
    readOnly,
    maxLength,
  } = config;

  // fix: A component is changing an uncontrolled input
  if (!field.value) {
    field.value = config.defaultValue ?? '';
  }

  const haveError: boolean = meta.touched && Boolean(meta.error);
  const onBlur = (e: any) => {
    field.onBlur(e);
    setFocus(false);
  };
  const onClick = (e: any) => {
    setFocus(true);
  };
  const props = {
    ...field,
    onBlur,
    onClick,
    disabled: disabled,
    required,
    autoFocus,
    variant,
    size,
    inputProps: {
      maxLength,
      autoComplete,
      readOnly,
    },
    InputLabelProps: {
      shrink: true,
    },
    rows,
    placeholder,
    margin,
    error: haveError && !focus,
    fullWidth,
  };

  const handleChange = (evt: any) => {
    setValue(evt.target.value.toUpperCase());
    if (evt.target.value === '') {
      setSearched(false);
      return;
    }
    debounceSearchAddress(evt.target.value, countryValue);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearchAddress = React.useCallback(
    debounce((value, country) => {
      setPostcode(value);
      setOpen(true);
      setSearched(true);
      if (country === 'GBR') {
        setOpenManual(false);
      }
    }, 1000),
    [],
  );

  const handleSelect = (item: any) => {
    selectPostcode(item);
    setOpen(false);
  };
  return (
    <>
      <FormLabel text={label} />
      <StyledTextField {...props} onChange={handleChange} />
      {!focus && meta.error && meta.touched ? <ErrorMessage>{meta.error}</ErrorMessage> : null}
      {/* @ts-ignore */}
      {field.value.length && searched && (loading || data) && open && !manualInput && countryValue === 'GBR' ? (
        <Wrapper>
          {loading ? (
            <LoadingWrapper>
              <ImgWrapper src="/images/logo/circle-logo-white.png" alt="Indicator" />
            </LoadingWrapper>
          ) : (
            <>
              {data.length ? (
                //@ts-ignore
                <DropBox className="fs-mask">
                  {data.map((item: Record<string, any>, index: number) => {
                    return (
                      <DropItem key={index} isFirst={index === 0} onClick={() => handleSelect(item)}>
                        <TruncateText font="book" size="13">
                          {/* @ts-ignore */}
                          {getTextAddress(item).text}
                        </TruncateText>
                        <Icon src="/images/icon/ico_go.svg" />
                      </DropItem>
                    );
                  })}
                </DropBox>
              ) : (
                <Text color="darkPrimary" variant="content" size="16">
                  {t('SEARCH_POST_CODE_NO_RESULT')}
                </Text>
              )}
            </>
          )}
        </Wrapper>
      ) : null}
      {!(field.value.length && searched && (loading || data)) && !manualInput ? (
        <ShowManualButton onClick={() => setOpenManual(true)}>
          <div style={{ display: 'flex' }}>
            <Icon src="/images/icon/ico_location.svg" style={{ marginRight: '16px' }} />
            <Text color="darkPrimary" size="16">
              {t('ENTER_MANUALLY')}
            </Text>
          </div>
          <Icon src="/images/icon/ico_go.svg" />
        </ShowManualButton>
      ) : null}
    </>
  );
};

export default SearchPostcodeField;
