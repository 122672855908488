import { useOnClickOutside } from '@/utils/helper';
import { InputAdornment, styled } from '@mui/material';
import { useField } from 'formik';
import * as React from 'react';
import { StyledTextField } from '../TextField';
import state from './state.json';
import FormLabel from '../Label';

export const CodeField = styled(StyledTextField, {
  shouldForwardProp: (propName: string) => propName !== 'disable',
})<{ disable: boolean }>(({ theme, disable }) => ({
  marginRight: '10px',
  cursor: 'pointer',
  input: {
    cursor: 'pointer',
  },
}));

export const StartAdornment = styled('div')(({ theme }) => ({
  color: '#fff',
  height: '48px',
  lineHeight: '48px',
}));

export const Icon = styled('img')(({ theme }) => ({
  cursor: 'pointer',
  width: '16px',
  height: '16px',
}));

export const Wrapper = styled('div')(({ theme }) => ({
  position: 'relative',
}));

export const DropBox = styled('div')(({ theme }) => ({
  padding: '0px 8px',
  border: 'solid 1px #272727',
  position: 'absolute',
  top: '47px',
  left: '0',
  zIndex: '1000',
  backgroundColor: '#000',
  display: 'block',
  overflowX: 'hidden',
  width: '100%',
  height: '220px',
}));

export const DropList = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '55px',
  overflowY: 'scroll',
  maxHeight: 'calc(100% - 56px)',
  width: 'calc(100% - 10px)',
}));

export const DropItem = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '40px',
  cursor: 'pointer',
  padding: '0 8px',
  '&:hover': {
    backgroundColor: '#dd8fe5',
  },
}));

export const FlagNameWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '40px',
}));

export const CountryName = styled('div')(({ theme }) => ({
  color: '#fff',
  fontSize: '14px',
  fontFamily: 'FuturaLTPro-Book',
  alignItems: 'center',
  lineHeight: '40px',
}));

const StatePickerField = ({ config, name, formik }: any) => {
  const [field, meta, { setValue }] = useField(name ?? 'TextField');
  const [open, setOpen] = React.useState(false);
  const [searchKey, setSearchKey] = React.useState('');
  const [countryName, setCountryName] = React.useState('');

  const {
    label,
    disabled,
    autoComplete = 'false',
    placeholder,
    variant = 'standard',
    margin,
    fullWidth = true,
    rows,
    size,
    required,
    autoFocus,
    readOnly,
    maxLength,
  } = config;

  const haveError: boolean = !!(meta.error && (meta.touched || formik.submitCount));

  const props = {
    ...field,
    disabled: disabled,
    required,
    autoFocus,
    variant,
    size,
    inputProps: {
      maxLength,
      autoComplete,
      readOnly,
    },
    InputLabelProps: {
      shrink: true,
    },
    rows,
    placeholder,
    margin,
    error: haveError,
    fullWidth,
  };

  React.useEffect(() => {
    setCountryName(state[0].name);
    setValue(state[0].abbreviation);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inputContainerRef = React.createRef();
  useOnClickOutside(inputContainerRef, () => setOpen(false));

  const handleChange = (evt: React.ChangeEvent<{ value: string }>) => {
    setSearchKey(evt.target.value.toLowerCase());
  };

  const handleSelect = (name: string, abbreviation: string) => {
    setValue(abbreviation);
    setCountryName(name);
    setOpen(false);
  };

  return (
    <>
      <FormLabel text={label} />
      <Wrapper ref={inputContainerRef}>
        <CodeField
          {...props}
          value={countryName}
          fullWidth
          onChange={(evt: React.ChangeEvent) => {
            evt.preventDefault();
            return;
          }}
          onClick={() => setOpen((prev) => !prev)}
          variant="standard"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {open ? <Icon src="/images/dropdown/up.png" alt="" /> : <Icon src="/images/dropdown/down.png" alt="" />}
              </InputAdornment>
            ),
          }}
        />
        {open ? (
          <DropBox className="open fs-mask">
            <StyledTextField
              fullWidth
              onChange={handleChange}
              autoFocus
              variant="standard"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon src="/images/dropdown/search.svg" alt="" />
                  </InputAdornment>
                ),
              }}
            />
            <DropList>
              {state
                .filter((country) => country.name.toLowerCase().includes(searchKey))
                .map((item, index) => {
                  return (
                    <DropItem key={index} onClick={() => handleSelect(item.name, item.abbreviation)}>
                      <FlagNameWrapper>
                        <CountryName>{item.name}</CountryName>
                      </FlagNameWrapper>
                    </DropItem>
                  );
                })}
            </DropList>
          </DropBox>
        ) : null}
      </Wrapper>
    </>
  );
};

export default StatePickerField;
