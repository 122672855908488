import { styled } from '@mui/material';

export const CardItemWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  backgroundColor: '#0f0f0f',
  paddingTop: 12,
  paddingBottom: 12,
  paddingLeft: 16,
  paddingRight: 16,
  marginBottom: 8
}));
export const CardInfo = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: 24
}));
export const CardNumber = styled('div')(({ theme }) => ({
  display: 'inline-block',
  fontFamily: 'FuturaLTPro-Medium',
  fontSize: '14px',
  fontWeight: 'normal',
  lineHeight: 1.71,
  letterSpacing: '1px',
  color: '#fff',
  marginLeft: 24,
}));
export const CardLogo = styled('img')(({ theme }) => ({
  display: 'inline-block',
}));
export const ExpiredCard = styled('div')(({ theme }) => ({
  display: 'flex',
  height: 20,
  cursor: 'pointer'
}));

export const ExpireDateWrapper = styled('div')(({ theme }) => ({
  color: '#999',
  fontSize: '14px',
  fontFamily: 'FuturaLTPro-Medium',
  lineHeight: 1.71,
  fontWeight: 'normal',
  paddingLeft: 16
}));
