import { Text } from '@/components/common';
import { styled } from '@mui/material/styles';

export const Wrapper = styled('div')(({ theme }) => ({
  backgroundRepeat: 'no-repeat',
  height: '100vh',
  width: '100vw',
  backgroundColor: '#dddcd8',
  backgroundSize: 'contain',
  backgroundPosition: 'bottom',
  backgroundImage:
    'url(/images/car/checkin-lights-375.png), url(https://static.theout.com/checkin/homepage/checkin-375.jpg)',
  [theme.breakpoints.up('sm')]: {
    backgroundImage:
      'url(/images/car/checkin-lights-540.png), url(https://static.theout.com/checkin/homepage/checkin-540.jpg),',
    minHeight: '1000px',
  },
  [theme.breakpoints.up('md')]: {
    backgroundImage:
      'url(/images/car/checkin-lights-768.png), url(https://static.theout.com/checkin/homepage/checkin-768.jpg)',
    minHeight: '1050px',
  },
  [theme.breakpoints.up('1000')]: {
    backgroundImage:
      'url(/images/car/checkin-lights-768.png), url(https://static.theout.com/checkin/homepage/checkin-768.jpg)',
    minHeight: '1100px',
  },
  [theme.breakpoints.up('lg')]: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage:
      'url(/images/car/checkin-lights-1024.png), url(https://static.theout.com/checkin/homepage/checkin-1024.jpg)',
    minHeight: '700px',
  },
  [theme.breakpoints.up('xl')]: {
    backgroundImage:
      'url(/images/car/checkin-lights-1280.png), url(https://static.theout.com/checkin/homepage/checkin-1280.jpg)',
  },
  [theme.breakpoints.up('xxl')]: {
    backgroundImage:
      'url(/images/car/checkin-lights-1440.png), url(https://static.theout.com/checkin/homepage/checkin-1440.jpg)',
  },
  [theme.breakpoints.up('xxxl')]: {
    backgroundImage:
      'url(/images/car/checkin-lights-1920.png), url(https://static.theout.com/checkin/homepage/checkin-1920.jpg)',
  },
}));

export const Title = styled(Text)(({ theme }) => ({
  marginTop: '70px',
  fontSize: '80px',
  fontWeight: '500',
  marginBottom: 0,
  textAlign: 'center',
  [theme.breakpoints.up('sm')]: {
    marginTop: '130px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '100px',
  },
  [theme.breakpoints.up('lg')]: {
    textAlign: 'left',
    marginTop: '20px',
  },
  [theme.breakpoints.up('xxl')]: {
    fontSize: '130px'
  },
}));

export const SubTitle = styled(Text)(({ theme }) => ({
  textAlign: 'center',
  fontSize: '18px',
  [theme.breakpoints.up('md')]: {
    fontSize: '25px',
  },
  [theme.breakpoints.up('lg')]: {
    textAlign: 'left',
    paddingRight: '0px',
  },
  [theme.breakpoints.up('xxl')]: {
    fontSize: '30px',
  },
}));
