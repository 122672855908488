import { styled } from '@mui/material/styles';
import { isString } from 'lodash';

export const LogoWrapper = styled('div', {
  shouldForwardProp: (propName: string) => propName !== 'width',
})<{ width: Record<string, string> | string }>(({ theme, width }) => ({
  display: 'flex',
  flexDirection: 'column',
  ...(isString(width)
    ? { width: `${width}px` }
    : {
        [theme.breakpoints.up('xxs')]: {
          width: `${width.xxs}px`,
        },
        [theme.breakpoints.up('xs')]: {
          width: `${width.xs}px`,
        },
        [theme.breakpoints.up('sm')]: {
          width: `${width.sm}px`,
        },
        [theme.breakpoints.up('md')]: {
          width: `${width.md}px`,
        },
        [theme.breakpoints.up('lg')]: {
          width: `${width.lg}px`,
        },
        [theme.breakpoints.up('xl')]: {
          width: `${width.xl}px`,
        },
        [theme.breakpoints.up('xxl')]: {
          width: `${width.xxl}px`,
        },
        [theme.breakpoints.up('xxxl')]: {
          width: `${width.xxxl}px`,
        },
      }),
}));

export const Logo = styled('div')(({ theme }) => ({
  img: {
    width: '100%',
  },
}));
