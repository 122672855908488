import { styled } from '@mui/material';

export const PaymentWrapper = styled('div')(({ theme }) => ({
  paddingTop: 32,
  paddingBottom: 24,
  [theme.breakpoints.down('sm')]: {
    paddingTop: 24,
  },
}));

export const PaymentTitle = styled('div')(({ theme }) => ({
  fontFamily: 'FuturaLTPro-Medium',
  fontSize: '11px',
  lineHeight: 1.45,
  letterSpacing: '2px',
  textTransform: 'uppercase',
  fontWeight: 500,
  marginBottom: 16,
}));

export const CardTickedIcon = styled('div')(({ theme }) => ({}));
export const GoIcon = styled('img')(({ theme }) => ({}));

export const AddPaymentMethodBtn = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  cursor: 'pointer',
}));
export const AddCardWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const AddCardText = styled('div')(({ theme }) => ({
  display: 'inline-block',
  fontFamily: 'FuturaLTPro-Book',
  fontSize: '16px',
  lineHeight: 1.5,
  letterSpacing: '1.5px',
  color: '#fff',
  marginLeft: 16,
}));
export const AddCardLogo = styled('img')(({ theme }) => ({
  display: 'inline-block',
}));

export const CardSecurity = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
}));
