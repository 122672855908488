import { Button, StyledForm, Text } from '@/components/common';
import { FormMarginDense, FormWrapper } from '@/components/form';
import CheckboxTerm from '@/components/page/Register/CheckboxTerm';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { UserType } from '@/utils/constant';
import { styled } from '@mui/material';
import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const Divider = styled('div')(({ theme }) => ({
  margin: '32px 0px',
  height: '1px',
  backgroundColor: '#191919',
  width: '100%',
}));

const DVLA: React.FC<any> = ({ onSubmit }) => {
  const [checkAgree, setCheckAgree] = React.useState(false);
  const dispatch = useAppDispatch();
  const userType = useAppSelector((state: any) => state.user.userType);
  useEffect(() => {
    dispatch({ type: 'HIDE_LOADING' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { t } = useTranslation();
  const content =
    userType === UserType.ADDITIONAL_DRIVER
      ? t('DVLA_CONSENT_DECLARATION_AD').split('\n')
      : t('DVLA_CONSENT_DECLARATION').split('\n');

  return (
    <FormWrapper>
      <Text color="darkPrimary" variant="title">
        {t('DRIVING_HISTORY_CHECK')}
      </Text>
      <Text color="darkSecondary" variant="content">
        {content[0]}
      </Text>
      <FormMarginDense value="32">
        <Text color="darkSecondary" variant="content">
          {content[1]}
        </Text>
      </FormMarginDense>

      <FormMarginDense value="32">
        <Text color="darkSecondary" variant="content">
          {content[2]}
        </Text>
      </FormMarginDense>

      <Divider />

      <Formik initialValues={{}} onSubmit={onSubmit}>
        {(props) => (
          <StyledForm>
            <div>
              <CheckboxTerm
                onClick={() => {
                  setCheckAgree((prev) => !prev);
                }}
                testId='test_checkbox_terms'
                checked={checkAgree}
                text={t('AGREE_TO_THESE_TERMS')}
              />
              <FormMarginDense>
                <Button disabled={!checkAgree} fullWidth variant="contained" color="white" type="submit">
                  {t('CONTINUE')}
                </Button>
              </FormMarginDense>
            </div>
          </StyledForm>
        )}
      </Formik>
    </FormWrapper>
  );
};

export default DVLA;
