export const saveInternalToken = (internalToken: string) => {
  localStorage.setItem('internalToken', internalToken);
};

export const getInternalToken = () => {
  return localStorage.getItem('internalToken');
};

export const clearSavedInternalToken = () => {
  localStorage.removeItem('internalToken');
};
