import { styled } from '@mui/material';
import { Form } from 'formik';
export const StyledForm = styled(Form, { name: 'Form' })(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  flex: '1 0',
  [theme.breakpoints.down('lg')]: {
    justifyContent: 'space-between',
  },
}));

export const RecapchaContainer = styled('div')(() => ({
  display: 'none',
}));

export const ErrorMessage = styled('div')(() => ({
  color: '#e54155',
  marginTop: 16,
  fontSize: 13,
  lineHeight: 1.6,
}));
