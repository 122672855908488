import { getPostcode } from '@/api/postcode';
import { useState, useEffect } from 'react';

export const useSearchPostcode = (postcode: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);

  const fetchData = async () => {
    const response = await getPostcode(postcode);
    return response;
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setLoading(true);
      fetchData().then((data) => {
        setData(data);
        setLoading(false);
      });
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postcode]);

  return [loading, data];
};
