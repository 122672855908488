import { StyledForm, Text, Button } from '@/components/common';
import { FormMarginDense, FormWrapper, FormLabel } from '@/components/form';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { KYCLayout } from '../../container/KYCLayout';
import { Icon, PassportWrapper } from './styles';
import { useOnfido } from '../../hook/useOnfido';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { get } from 'lodash';
import { useEffect } from 'react';

const PassportUploadPage: any = () => {
  const { t } = useTranslation();
  const userInfo = useAppSelector((state: any) => get(state, 'user.userInfo'));
  const [, , triggerPassport] = useOnfido(userInfo?.id);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch({ type: 'HIDE_LOADING' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (values: any, form: FormikHelpers<any>) => {
    triggerPassport();
  };
  return (
    <FormWrapper>
      <div id="onfido-mount"></div>
      <Text color="darkPrimary" variant="title">
        {t('VERIFY_YOUR_IDENTITY')}
      </Text>
      <Text variant="content" color="darkSecondary">
        {t('PASSPORT_IDENTITY_SUBTITLE')}
      </Text>
      {/* @ts-ignore */}
      <Formik initialValues={{}} onSubmit={onSubmit}>
        {(props) => (
          <StyledForm>
            <div>
              <FormMarginDense>
                <FormLabel text="required" />
                <PassportWrapper>
                  <Icon src="/images/icon/ico_menu_passport.svg" alt="" />
                  <Text variant="content" color="darkPrimary">
                    {t('PASSPORT')}
                  </Text>
                </PassportWrapper>
              </FormMarginDense>
            </div>
            <div>
              <FormMarginDense>
                <Button fullWidth variant="contained" color="white" type="submit">
                  {t('CONTINUE')}
                </Button>
              </FormMarginDense>
            </div>
          </StyledForm>
        )}
      </Formik>
    </FormWrapper>
  );
};

PassportUploadPage.getLayout = KYCLayout;
export default PassportUploadPage;
