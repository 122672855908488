import { CircleCheckbox } from '@/components/common';
import { REGEX_CHARACTERS, REGEX_SPECIAL_CHARACTERS } from '@/utils/constant';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextWrapper, Wrapper } from './styled';

const CheckPassword: React.FunctionComponent<any> = ({ newPassword }: { newPassword: string }) => {
  const { t } = useTranslation();

  const checked = (status: number) => {
    switch (status) {
      case 0:
        return !!newPassword && newPassword.length > 9;
      case 1:
        return !!newPassword && REGEX_CHARACTERS.test(newPassword);
      case 2:
        return !!newPassword && REGEX_SPECIAL_CHARACTERS.test(newPassword);
      default:
        return false;
    }
  };

  const text = t('PASSWORD_CHECK_POLICY_TEXT')?.split('\n');

  return (
    <>
      {text.map((item, index) => {
        return (
          <Wrapper key={index}>
            <CircleCheckbox disabled={!checked(index)} size="small" checked={checked(index)} />
            {/* <Radio disable={!checked(index)} src={iconSrc(checked(index))} alt="" /> */}
            <TextWrapper>{item}</TextWrapper>
          </Wrapper>
        );
      })}
    </>
  );
};
export default CheckPassword;
