import { getStep } from '@/api/user';
import { useAppDispatch } from '@/redux/hooks';

export const useSidebar = (uid: string) => {
  const dispatch = useAppDispatch();
  if (!uid) return [];

  const reloadStep = async (exceptStep?: string[]) => {
    const response = await getStep(uid).then((listStep) => {
      dispatch({ type: 'RELOAD_STEP', payload: { data: listStep, exceptStep: exceptStep } });
      return listStep;
    });
    return response;
  };
  return [reloadStep];
};
