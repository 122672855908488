import { styled, TextField as MuiTextField } from '@mui/material';
import { useField } from 'formik';
import React from 'react';
import { ErrorMessage } from '../ErrorMessage';
import FormLabel from '../Label';

const TextFieldWrapper = styled('div')(({ theme }) => ({
  display: 'block',
  width: '100%',
}));

export const StyledTextField = styled(MuiTextField, {
  shouldForwardProp: (propName: string) => propName !== 'themeColor' && propName !== 'size',
})<{ themeColor: string }>(({ theme, themeColor = 'dark', size }) => ({
  '& input:-webkit-autofill:valid': {
    '-webkit-box-shadow': '0 0 0 1000px #000 inset !important',
    '-webkit-text-fill-color': themeColor === 'dark' ? '#fff !important' : '#000 !important',
    '-webkit-transition-delay': '99999s',
    'caret-color': themeColor === 'dark' ? '#fff' : '#000'
  },
  input: {
    color: themeColor === 'dark' ? '#fff' : '#000',
    fontSize: size ?? '15px',
    letterSpacing: '1px',
    lineHeight: 1.07,
    height: '16px',
    WebkitBoxShadow: `0 0 0 1000px ${themeColor === 'dark' ? 'black' : 'white'} inset`,
    WebkitTextFillColor: themeColor === 'dark' ? '#fff' : '#000',
    [theme.breakpoints.up('sm')]: {
      fontSize: 16,
      lineHeight: 1,
      paddingTop: 5
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 5
    },
  },
  '& label': {
    color: '#999',
  },
  '& .StripeElement': {
    padding: '1px 0 !important',
  },
  '& .MuiInput-underline': {
    padding: '11px 0',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: themeColor === 'dark' ? '#fff' : '#000',
    left: '0',
    transformOrigin: 'left center',
  },
  '& .Mui-error:after': {
    borderBottomColor: '#e54155',
  },
  '& .MuiFormHelperText-root.Mui-error': {
    marginTop: 16,
    color: '#e54155',
    fontSize: 13,
    lineHeight: 1.6,
  },
  '& .MuiInput-underline:before': {
    borderBottomColor: themeColor === 'dark' ? '#272727' : '#dedede',
  },
  '& .MuiInput-underline:hover:before': {
    borderBottomColor: themeColor === 'dark' ? '#272727' : '#dedede',
    borderBottom: `1px solid ${themeColor === 'dark' ? '#272727' : '#dedede'}`,
  },
}));

const TextField = ({ config, name, formik }: any) => {
  const [field, meta] = useField(name ?? 'TextField');
  const [focus, setFocus] = React.useState<boolean>(false);
  const {
    label,
    disabled,
    autoComplete = 'false',
    placeholder,
    variant = 'standard',
    margin,
    fullWidth = true,
    type = 'text',
    rows,
    size,
    required,
    autoFocus,
    readOnly,
    maxLength,
    inputComponent,
    inputProps,
    endAdornment,
    themeColor = 'dark',
    toUpperCase,
    onClick,
    InputProps,
  } = config;

  // fix: A component is changing an uncontrolled input
  if (!field.value) {
    field.value = config.defaultValue ?? '';
  }
  if (toUpperCase) {
    field.value = field.value.toUpperCase();
  }
  const haveError: boolean = meta.touched && Boolean(meta.error);
  const onBlur = (e: any) => {
    field.onBlur(e);
    setFocus(false);
  };
  const onClickFocus = (e: any) => {
    onClick && onClick()
    setFocus(true);
  };
  const props = {
    ...field,
    onBlur,
    onClick: onClickFocus,
    disabled: disabled,
    required,
    autoFocus,
    variant,
    size,
    inputProps: {
      maxLength,
      autoComplete,
      readOnly,
    },
    InputLabelProps: {
      shrink: true,
    },
    InputProps: {
      ...InputProps,
      inputComponent,
      inputProps,
      endAdornment,
    },
    rows,
    placeholder,
    margin,
    error: haveError && !focus,
    fullWidth,
    type,
    ...themeColor,
  };
  return (
    <TextFieldWrapper>
      <FormLabel text={label} />
      <StyledTextField {...props} />
      {!focus && meta.error && meta.touched ? <ErrorMessage>{meta.error}</ErrorMessage> : null}
    </TextFieldWrapper>
  );
};

export default TextField;
