//@ts-nocheck
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModalButton, ModalHeader, Wrapper, WrapperText, TitleWrapper, ContentWrapper, BodyWrapper, ContactUsButton } from './styled';
import { getErrorTitleOfRemoveCreditCard, getErrorContentOfRemoveCreditCard } from '@/utils/helper';
import { CONTACT_EMAIL } from '@/utils/constant';

const DeletePaymentMethodFailure: React.FC<any> = ({ closeModal, error }) => {
  const { t } = useTranslation();
  const handleClose = () => {
    window.location.href = CONTACT_EMAIL;
  };
  return (
    <Wrapper>
      <ModalHeader color="primary" variant="title" size="18">
        <img style={{ width: '40px', height: '40px' }} src="/images/icon/Checklist_Error.svg" alt="" />
      </ModalHeader>
      <BodyWrapper>
        <WrapperText>
          <TitleWrapper
            color="primary"
            variant="titleModal"
          >
            {t(getErrorTitleOfRemoveCreditCard(error))}
          </TitleWrapper>
          <ContentWrapper>
            {t(getErrorContentOfRemoveCreditCard(error))}
          </ContentWrapper>
        </WrapperText>
        <ModalButton fullWidth variant="contained" color="dark" onClick={() => closeModal()}
          data-testid = 'btn-close'
        >
          {t('CLOSE')}
        </ModalButton>
        <ContactUsButton fullWidth variant="contained" color="white" onClick={handleClose}
          sx={{ marginTop: '16px' }}
          data-testid = 'btn-contact-us'
        >
          {t('CONTACT_US')}
        </ContactUsButton>
      </BodyWrapper>
    </Wrapper>
  );
};

export default DeletePaymentMethodFailure;
