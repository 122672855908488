import { SVGProps } from "react";

const IconError = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20 40c11.046 0 20-8.954 20-20S31.046 0 20 0 0 8.954 0 20s8.954 20 20 20zm.167-30a1 1 0 0 1 1 1v11.333a1 1 0 1 1-2 0V11a1 1 0 0 1 1-1zM20 29.167a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5z"
        fill="#E54155"
      />
    </svg>
  );
};

export default IconError;
