import { Button, Text } from '@/components/common';
import { styled } from '@mui/material';
export const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const WrapperText = styled('div')(() => ({
  maxWidth: '247px',
  width: '100%',
  textAlign: 'center',
}));

export const ModalButton = styled(Button)(({ theme }) => ({
  width: '144px',
  marginTop: '40px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginTop: '24px',
  },
}));

export const ModalHeader = styled(Text)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    marginTop: '8px',
  },
}));
