import Button from '@/components/common/Button';

import { Text } from '@/components/common';
import { styled } from '@mui/material/styles';

export const Content = styled('div')(({ theme }) => ({
  height: '100%',
  padding: '24px 24px 24px',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'flex-start',
  [theme.breakpoints.up('sm')]: {
    padding: '56px 56px',
  },
  [theme.breakpoints.up('md')]: {
    padding: '48px 48px',
  },
  [theme.breakpoints.up('lg')]: {
    width: '50%',
    padding: '0 56px',
    justifyContent: 'center',
  },
  [theme.breakpoints.up('xl')]: {
    width: '50%',
    padding: '0 88px',
    justifyContent: 'center',
  },
  [theme.breakpoints.up('xxl')]: {
    padding: '0 96px',
  },
  [theme.breakpoints.up('xxxl')]: {
    width: '50%',
    padding: '0 216px',
    justifyContent: 'center',
  },
}));

export const Divider = styled('div')(({ theme }) => ({
  margin: '24px 0px',
  height: '1px',
  backgroundColor: '#bbb',
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    margin: '32px 0px',
  },
  [theme.breakpoints.up('lg')]: {
    margin: '48px 0px',
  },
}));

export const WelcomeText = styled('div')(({ theme }) => ({
  fontSize: '16px',
  lineHeight: '1.5',
  fontFamily: 'FuturaLTPro-Book',
  letterSpacing: '1px',
  color: '#000',
  [theme.breakpoints.down('sm')]: {
    fontSize: '15px',
  },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  fontSize: '13px',
  letterSpacing: '2px',
  borderRadius: '0px',
  height: '48px',
  minWidth: '144px',
  marginTop: '32px',
  fontFamily: 'FuturaLTPro-Medium',
  width: 'fit-content',
  boxShadow: 'none',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginTop: '24px',
  },
}));

export const Title = styled(Text)(({ theme }) => ({
  marginTop: '8px',
  marginBottom: '24px',
  fontSize: '24px',
  fontWeight: '500',
  [theme.breakpoints.up('sm')]: {
    marginTop: '16px',
    marginBottom: '32px',
    fontSize: '32px',
  },
  [theme.breakpoints.up('md')]: {
    paddingRight: '184px',
  },
  [theme.breakpoints.up('lg')]: {
    paddingRight: '0px',
  },
  [theme.breakpoints.up('xxl')]: {
    fontSize: '40px',
  },
}));

export const SubTitle = styled(Text)(({ theme, outro }) => ({
  [theme.breakpoints.up('md')]: {
    paddingRight: outro ? '96px' : '184px',
  },
  [theme.breakpoints.up('lg')]: {
    paddingRight: '0px',
  },
}));

export const RecapchaContainer = styled('div')(() => ({
  display: 'none',
}));

export const ModalHeader = styled(Text)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: 16,
  fontSize: 16,
  [theme.breakpoints.up('sm')]: {
    fontSize: 18,
  },
}));