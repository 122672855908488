//@ts-nocheck
import { forwardRef, useImperativeHandle, useRef } from 'react';

const iframeStyles = {
  base: {
    backgroundColor: '#000',
    color: '#fff',
    fontFamily: 'FuturaLTPro-Book',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '1.4375em',
    '::placeholder': {
      fontFamily: 'FuturaLTPro-Book',
      color: '#555555',
    },
    ':-webkit-autofill': {
      color: '#fff',
      animation: 'none'
    },
  },
  invalid: {
    iconColor: '#eb1c26',
    color: '#eb1c26',
  },
  complete: {
    iconColor: '#cbf4c9',
  },
};

const StripeInputDark: React.FC<any> = forwardRef((props, inputRef) => {
  const { component: Component, name, ...other } = props;
  const elementRef = useRef(null);
  useImperativeHandle(inputRef, () => ({
    focus: () => elementRef?.current?.focus,
  }));
  const placeHolder = name === 'expiryDate' ? 'MM/YY' : '';
  return (
    <Component
      onReady={(element: any) => (elementRef.current = element)}
      {...other}
      options={{ style: iframeStyles, placeholder: placeHolder }}
    />
  );
});

export default StripeInputDark;
