import { styled } from '@mui/material';
export const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: '8px',
  cursor: 'pointer',
}));
export const TextWrapper = styled('div', {
  shouldForwardProp: (propName: string) => propName !== 'mode',
  name: 'CheckIcon',
})<{ mode: string }>(({ theme, mode }) => ({
  color: mode === 'dark' ? '#fff' : '#000',
  fontSize: '15px',
  lineHeight: 1.07,
  letterSpacing: '1px',
  paddingLeft: '12px',
  [theme.breakpoints.up('sm')]: {
    fontSize: '16px',
    lineHeight: 1,
  },
}));
