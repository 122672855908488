import { addCreditCard, getClientSecretStripe } from '@/api/payment';
import { StyledForm, Text } from '@/components/common';
import PaymentFailedModal from '@/components/modal/PaymentFailed';
import PendingKycModal from '@/components/modal/PendingKYCModal';
import RejectedKycModal from '@/components/modal/RejectedKYCModal';
import { useUser } from '@/hooks/useUser';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { CARD_TYPE, UserType } from '@/utils/constant';
import { ModalRenderer } from '@/utils/modalUtils';
import { cardNameSchema, PostcodeSchema } from '@/utils/validation';
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Formik } from 'formik';
import { get } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import CardList from './CardList';
import PaymentForm from './PaymentForm';
import StripeInputDark from '@/libs/stripe/StripeInput/StripeInputDark';
import { ContinueBtn, ContinueBtnForCP, DepositPaymentWrapper, AddPaymentMethodBtnForCP, PaymentMethodSubTitle } from './styled';
import AddNewPaymentModal from '@/components/modal/AddNewPayment';
import CardListForCP from './CardListForCP';


const schema = yup.object().shape({
  cardName: cardNameSchema,
  postCode: PostcodeSchema,
});

const DepositPayment: React.FC<any> = () => {
  const { t } = useTranslation();
  const userId = useAppSelector((state: any) => state.auth.userId);
  const cardList = useAppSelector((state: any) => get(state, 'user.userInfo.credit_cards'));
  const cardWithoutApplePay = cardList?.filter((card: any) => card.wallet !== CARD_TYPE.APPLE);
  const formikRef = useRef(null);
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isCompleted, setCompleted] = useState(false);
  const [reloadUser] = useUser(userId);
  const userType = useAppSelector((state: any) => state.user.userType);

  useEffect(() => {
    (async () => {
      dispatch({ type: 'SHOW_LOADING' });
      await reloadUser()
      dispatch({ type: 'HIDE_LOADING' });

    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (cardWithoutApplePay?.length > 0) {
      dispatch({
        type: 'UPDATE_STATUS',
        payload: { deposit_passed: true },
      });
    } else {
      dispatch({
        type: 'UPDATE_STATUS',
        payload: { deposit_passed: false },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardWithoutApplePay]);

  const onSubmit = async (values: any) => {
    dispatch({ type: 'SHOW_LOADING' });
    if (!stripe || !elements) {
      return;
    }
    try {
      const { client_secret } = await getClientSecretStripe(userId);
      const resultConfirmCardSetup = await stripe.confirmCardSetup(client_secret, {
        payment_method: {
          card: elements.getElement(CardNumberElement)!,
          billing_details: {
            name: values.cardName?.toUpperCase(),
            address: {
              postal_code: values.postCode?.toUpperCase(),
            },
          },
        },
      });
      if (!resultConfirmCardSetup.error) {
        const cardTokenInfo = await stripe.createToken(elements.getElement(CardNumberElement)!);
        if (!cardTokenInfo.error) {
          const bodyAddCard = {
            first_name: values.cardName?.toUpperCase(),
            last_name: '',
            token: resultConfirmCardSetup.setupIntent.payment_method,
          };

          await addCreditCard(userId, bodyAddCard);
          await reloadUser();
          dispatch({
            type: 'UPDATE_STATUS',
            payload: { deposit_passed: true },
          });
        }
      } else {
        ModalRenderer.open({
          component: PaymentFailedModal,
          theme: 'white',
          inMobile: 'bottom',
          props: {
            card: resultConfirmCardSetup.error?.payment_method?.card,
          },
        });
      }
    } catch (err) {
      //define logging
    } finally {
      dispatch({ type: 'HIDE_LOADING' });
    }
  };

  const onHandleContinue = async () => {
    dispatch({ type: 'SHOW_LOADING' });
    reloadUser().then((user) => {
      const kycRequired = get(user, ['insurance', 'requires'], []);
      const verification_status = get(user, ['verification_status'], null);
      dispatch({ type: 'HIDE_LOADING' });
      if (
        verification_status === 'manual_verification' ||
        (kycRequired.length === 1 && kycRequired?.includes('User.selfie-in-verification'))
      ) {
        ModalRenderer.open({
          component: PendingKycModal,
          theme: 'white',
          inMobile: 'bottom',
        });
        return;
      }

      if (verification_status === 'rejected') {
        ModalRenderer.open({
          component: RejectedKycModal,
          theme: 'white',
          inMobile: 'bottom',
        });
        return;
      }
      navigate('/thank-you');
    });
  };

  const addPaymentMethod = async () => {
    ModalRenderer.open({
      component: AddNewPaymentModal,
      theme: 'white',
      inMobile: 'bottom',
    });
    return;
  };

  return (
    <>
      {(userType === UserType.CONCIERGE_PORTAL) ? (<>
        <Text color="darkPrimary" variant="title">
          {t('PAYMENT_METHOD')}
        </Text>
        <PaymentMethodSubTitle sx={{ marginBottom: '8px' }}>
          <Trans
            i18nKey="PAYMENT_METHOD_SUBTITLE_1"
          />
        </PaymentMethodSubTitle>
        <PaymentMethodSubTitle>
          <Trans
            i18nKey="PAYMENT_METHOD_SUBTITLE_2"
          />
        </PaymentMethodSubTitle>
        <DepositPaymentWrapper>
          <CardListForCP cardList={cardWithoutApplePay} />
          <ContinueBtnForCP
            fullWidth
            variant="contained"
            color="white"
            type="submit"
            onClick={addPaymentMethod}
          >
            {t('ADD_PAYMENT_METHOD')}
          </ContinueBtnForCP>
        </DepositPaymentWrapper>
        <AddPaymentMethodBtnForCP fullWidth variant="contained" color="white" onClick={onHandleContinue}>
          {t('DEPOSIT_CONTINUE_BTN')}
        </AddPaymentMethodBtnForCP>
      </>) : (<>
        <Text color="darkPrimary" variant="title">
          {t('DEPOSIT_PAYMENT_TITLE')}
        </Text>
        <Text color="darkSecondary" variant="content" size="16">
          <Trans
            i18nKey="DEPOSIT_PAYMENT_SUBTITLE"
            components={{
              link1: (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                  href="https://theout.zendesk.com/hc/en-gb/articles/9944136066333-How-much-is-the-deposit-to-secure-my-booking-"
                  target="blank"
                />
              ),
            }}
          />
        </Text>
        {cardWithoutApplePay?.length > 0 ? (
          <DepositPaymentWrapper>
            <CardList cardList={cardWithoutApplePay} />
            <ContinueBtn fullWidth variant="contained" color="white" onClick={onHandleContinue}>
              {t('DEPOSIT_CONTINUE_BTN')}
            </ContinueBtn>
          </DepositPaymentWrapper>
        ) : (
          <Formik
            initialValues={{
              cardName: '',
              postCode: '',
            }}
            validationSchema={schema}
            onSubmit={onSubmit}
            innerRef={formikRef}
          >
            {(props) => (
              <StyledForm>
                <PaymentForm StripeInput={StripeInputDark} setCompleted={setCompleted} />
                <ContinueBtn
                  fullWidth
                  variant="contained"
                  color="white"
                  type="submit"
                  disabled={!(!Boolean(props.errors.cardName) && !Boolean(props.errors.postCode) && isCompleted)}
                >
                  {t('DEPOSIT_CONTINUE_BTN')}
                </ContinueBtn>
              </StyledForm>
            )}
          </Formik>
        )}
      </>)}
    </>
  );
};
export default DepositPayment;