import { UserType } from '@/utils/constant';
import IntroPage from '@/components/page/Intro';
import { useEffect } from 'react';
import { useAppDispatch } from '@/redux/hooks';

const RentalCarPage = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch({ type: 'HIDE_LOADING' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <IntroPage userType={UserType.RENTAL_CARS} />;
};
export default RentalCarPage;
