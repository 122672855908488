import { Button } from '@/components/common';
import { useAppSelector } from '@/redux/hooks';
import { Content } from '@/styles/common';
import React from 'react';
import { ButtonWrapper, ErrorDetail, Logo, SubTitle, Title, Wrapper } from './styled';
import { CONTACT_EMAIL } from '@/utils/constant';

const ErrorPage: React.FC<any> = () => {
  const error = useAppSelector((state: any) => state.error);

  const handleContactUsBtn = () => {
    window.location.href = CONTACT_EMAIL;
  };
  return (
    <Wrapper className="fs-unmask">
      <Content>
        <ErrorDetail>
          <Logo>
            <img src={`/images/icon/Icon_Large_Error.svg`} alt="" width={32} height={32} />
          </Logo>
          <Title variant="title" color="primary">
            {error?.title}
          </Title>
          <SubTitle variant="content" color="secondary">
            {error?.content}
          </SubTitle>
        </ErrorDetail>
        <ButtonWrapper>
          <Button color="dark" fullWidth onClick={handleContactUsBtn}>
            Contact Us
          </Button>
        </ButtonWrapper>
      </Content>
    </Wrapper>
  );
};
export default ErrorPage;
