import { authHttpClient } from '@/libs/axios';

let cachedData: Record<any, any>[] = [];

export const getCountries = async () => {
  try {
    if (!cachedData.length) {
      const response = await authHttpClient.get(`/countries`);
      const data: Record<any, any>[] = await response.data;
      cachedData = data;
      return data;
    }
    return cachedData;
  } catch (error: any) {
    throw error.error;
    //define logging
  }
};
