import { getCardLogoByType } from '@/utils/cards';
import { getExpireDate } from '@/utils/helper';
import { CardInfo, CardItemWrapper, CardLogo, CardNumber, ExpiredCard, ExpireDateWrapper } from './styled';
import { ModalRenderer } from '@/utils/modalUtils';
import ConfirmDeleteCard from '@/components/modal/ConfirmDeleteCard';

const CardItem: React.FC<any> = ({ card }) => {
  const logo = getCardLogoByType(card?.type, true);
  const expireDate = getExpireDate(card?.month.toString(), card?.year.toString());
  const removePaymentMethod = async () => {
    ModalRenderer.open({
      component: ConfirmDeleteCard,
      theme: 'white',
      inMobile: 'bottom',
      props: {
        card: card
      }
    });
    return;
  };
  return (
    <CardItemWrapper>
      <CardInfo>
        <CardLogo src={logo} width={25} height={16} />
        <CardNumber>
          •••• <span className="fs-mask">{card?.number}</span>
        </CardNumber>
        <ExpireDateWrapper>
          <span className="fs-mask">{expireDate}</span>
        </ExpireDateWrapper>
      </CardInfo>
      <ExpiredCard onClick={removePaymentMethod}>
        <img style={{ width: '20px', height: '20px' }} src="/images/icon/Icon_Remove.svg" alt="" />
      </ExpiredCard>
    </CardItemWrapper>
  );
};
export default CardItem;
