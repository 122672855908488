import { styled, TextField as MuiTextField } from '@mui/material';
export const TextFieldWrapper = styled('div')(({ theme }) => ({
  display: 'block',
  width: '100%',
  div: {
    letterSpacing: '1px',
  },
}));

export const StyledTextField = styled(MuiTextField, {
  shouldForwardProp: (propName: string) => propName !== 'themeColor',
})<{ themeColor: string }>(({ theme, themeColor = 'light' }) => ({
  '& label': {
    color: '#999',
  },
  '& .StripeElement': {
    paddingTop: 2,
    paddingBottom: 1,
    backgroundColor: 'transparent !important',
  },
  '& .MuiInput-underline': {
    padding: '11px 0',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: themeColor === 'light' ? '#000' : '#fff',
    left: '0',
    transformOrigin: 'left center',
  },
  '& .Mui-error:after': {
    borderBottomColor: '#e54155',
  },
  '& .MuiFormHelperText-root.Mui-error': {
    marginTop: 16,
    color: '#e54155',
    fontSize: 13,
    lineHeight: 1.6,
  },
  '& .MuiInput-underline:before': {
    borderBottomColor: themeColor === 'light' ? '#dedede' : '#272727',
  },
  '& .MuiInput-underline:hover:before': {
    borderBottomColor: themeColor === 'light' ? '#dedede' : '#272727',
    borderBottom: `1px solid ${themeColor === 'light' ? '#dedede' : '#272727'}`,
  },
}));
