//@ts-nocheck
import React, { Component } from 'react';
import { ModalComponent } from '@/components/modal/BaseModal';

export class ModalWrapper extends Component {
  state = {
    modals: [], // includes multiple models
  };

  totalIndex = 0;

  componentDidUpdate(_, prevState) {
    const body = document.querySelector('body');
    if (this.state.modals.length) {
      body.classList.add('body-unscrollable');
    } else {
      body.classList.remove('body-unscrollable');
    }
  }

  open = ({ ...args }) => {
    const modal = { ...args };
    let { modals } = this.state;

    const listComponentModal = modals.map((item) => {
      return item.component;
    });

    if (listComponentModal.includes(modal.component)) return;

    this.totalIndex++;

    modal.isVisible = true;
    modal.id = this.totalIndex;

    if (!modal.ref) {
      modal.ref = React.createRef();
    }

    modals.push({ ...modal });
    this.setState({ modals });
  };

  close = (props, id) => {
    if (props === 'all') {
      this.setState({ modals: [] });
      return;
    }
    if (props?.component) {
      const componentName = props?.component;
      let { modals } = this.state;
      let newModals = modals.filter((item) => item.component !== componentName);
      this.setState({ modals: newModals });
      return;
    }
    let { modals } = this.state;
    let newModals = modals.filter((item) => item.id !== id);
    this.setState({ modals: newModals });
  };

  updateProps = ({ ...props }, index) => {
    const { modals } = this.state;
    modals[index].props = { ...modals[index].props, ...props };
    this.setState({ modals });
  };

  render() {
    const { modals } = this.state;

    return modals.map((modal, index) => (
      <ModalComponent key={modal.id + '' + index} closeModal={(props) => this.close(props, modal.id)} {...modal} />
    ));
  }
}
