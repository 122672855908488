import { styled } from '@mui/material';

export const Label = styled('div')(({ theme }) => ({
  fontSize: '11px',
  lineHeight: '16px',
  fontFamily: 'FuturaLTPro-Medium',
  letterSpacing: '2px',
  color: '#999',
  textTransform: 'uppercase',
  transform: 'translate(0,-1.5px) scale(1) !important',
  [theme.breakpoints.up('sm')]: {
    lineHeight: '16px',
  },
}));

const FormLabel = ({ text }: { text: string }) => {
  return <Label>{text}</Label>;
};
export default FormLabel;
