import { styled } from '@mui/material';
export const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: '8px',
  [theme.breakpoints.up('sm')]: {
    marginTop: '16px',
  },
}));

export const TextWrapper = styled('div')(({ theme }) => ({
  color: '#7c7c7c',
  fontSize: '14px',
  lineHeight: 1.43,
  letterSpacing: '1px',
  paddingLeft: '12px',
  [theme.breakpoints.up('sm')]: {
    fontSize: 16,
    lineHeight: 1,
  },
}));

export const Radio = styled('img', {
  shouldForwardProp: (propName: string) => propName !== 'disable',
})<{ disable: boolean }>(({ theme, disable }) => ({
  width: '16px',
  height: '16px',
  ...(disable && {
    opacity: '0.2',
  }),
}));
