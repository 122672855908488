import { PENDING, REJECTED, VERIFIED } from '@/components/common/Sidebar/constant';
import { styled } from '@mui/material';
import React from 'react';

export const Wrapper = styled('div', {
  shouldForwardProp: (propName: string) => propName !== 'isLast',
})<{ isLast: boolean }>(({ theme, isLast }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '56px',
  padding: '16px 0px',
  justifyContent: 'space-between',
  ...(!isLast && {
    borderBottom: '1px solid #191919',
  }),
}));
export const ContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

export const TextWrapper = styled('div')(({ theme }) => ({
  color: '#fff',
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '1px',
  paddingLeft: '16px',
}));

export const Radio = styled('img', {
  shouldForwardProp: (propName: string) => propName !== 'disable',
})<{ disable: boolean }>(({ theme, disable }) => ({
  width: '16px',
  height: '16px',
  ...(disable && {
    opacity: '0.5',
  }),
}));

export const Icon = styled('img')(({ theme }) => ({
  cursor: 'pointer',
}));

const statusIcon = (status: number) => {
  switch (status) {
    case VERIFIED:
      return '/images/radio/checked.svg';
    case PENDING:
      return '/images/radio/warning.svg';
    case REJECTED:
      return '/images/radio/error.svg';
    default:
      return '';
  }
};

const KYCElement: React.FunctionComponent<any> = ({
  ico,
  status,
  content,
  isLast,
  disable = false,
}: {
  ico: string;
  status: number;
  content: string;
  isLast: boolean;
  disable: boolean;
}) => {
  return (
    <Wrapper isLast={isLast}>
      <ContentWrapper>
        <Icon src={`/images/verify-identity/${ico}.svg`} alt="" />
        <TextWrapper>{content}</TextWrapper>
      </ContentWrapper>
      {statusIcon(status) ? <Radio src={statusIcon(status)} disable={disable ? true : false} alt="" /> : null}
    </Wrapper>
  );
};
export default KYCElement;
