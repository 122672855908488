import { removeCreditCard } from "@/api/payment";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { useTranslation } from "react-i18next";
import CardItem from "./CardItem";
import { useUser } from '@/hooks/useUser';
import { ContinueBtnRemove, MessageWrapper, ModalHeader } from "./styled";
import { notify } from "@/components/common/Toast";
import { ModalRenderer } from "@/utils/modalUtils";
import DeletePaymentMethodFailure from "../DeletePaymentMethodFailure";

const ConfirmDeleteCard: React.FC<any> = ({ closeModal, card }) => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state: any) => state.auth.userId);
  const [reloadUser] = useUser(userId);
  const { t } = useTranslation();
  const onClose = () => {
    closeModal();
  };
  const onDelete = async () => {
    try {
      dispatch({ type: 'SHOW_LOADING' });
      await removeCreditCard(userId, card?.number);
      await reloadUser();
      notify(t('PAYMENT_METHOD_REMOVED'));
    } catch (err: any) {
      ModalRenderer.open({
        component: DeletePaymentMethodFailure,
        theme: 'white',
        inMobile: 'bottom',
        props: {
          error: err
        }
      });
    }
    finally {
      dispatch({ type: 'HIDE_LOADING' });
      onClose();
    }
  };
  return (
    <>
      <ModalHeader color="primary" variant="title" size="18" sx={{ marginBottom: '16px', marginTop: '0px !important', lineHeight: '28px' }}>
        {t('REMOVE_PAYMENT_METHOD')}
        <img
          style={{ width: '20px', height: '20px', cursor: 'pointer' }}
          src="/images/icon/ico_close_x.svg"
          onClick={onClose}
          data-testid='close'
          alt=""
        />
      </ModalHeader>
      <MessageWrapper>
        {t('REMOVE_PAYMENT_METHOD_CONTENT')}
      </MessageWrapper>
      <CardItem card={card} />
      <ContinueBtnRemove
        fullWidth
        variant="contained"
        color="white"
        type="submit"
        onClick={onDelete}
        data-testid='delete'
      >
        {t('REMOVE_PAYMENT_METHOD')}
      </ContinueBtnRemove>
    </>
  );
};

export default ConfirmDeleteCard;
