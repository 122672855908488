//@ts-nocheck
export class ModalRenderer {
  _currentGlobalLoader = null;
  static registerModal(ref) {
    this._currentGlobalLoader = ref;
  }

  static open({ ...args }) {
    if (this._currentGlobalLoader) {
      this._currentGlobalLoader.open({ ...args });
    }
  }

  static updateProps({ ...props }, index) {
    if (this._currentGlobalLoader) {
      this._currentGlobalLoader.updateProps({ ...props }, index);
    }
  }

  static close(index) {
    if (this._currentGlobalLoader) {
      this._currentGlobalLoader.close(index);
    }
  }
}
