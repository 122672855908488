import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xxs: true; // adds the `mobile` breakpoint
    xxl: true;
    xxxl: true;
    '1000': true;
  }
}

// A custom theme for this app
const theme = createTheme({
  typography: {
    fontFamily: 'FuturaLTPro-Book',
  },
  palette: {
    primary: {
      main: '#fff',
    },
    secondary: {
      main: '#999',
    },
    error: {
      main: red.A400,
    },
  },
  breakpoints: {
    values: {
      xxs: 0,
      xs: 375,
      sm: 540,
      md: 768,
      '1000': 1000,
      lg: 1024,
      xl: 1280,
      xxl: 1440,
      xxxl: 1920,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        html, body {
          line-height: 1;
          letter-spacing: 1px;
        }
        * {
          box-sizing: border-box; 
        };
        input {
          &:-webkit-autofill,
          &:-webkit-autofill:hover,
          &:-webkit-autofill:focus,
          &:-webkit-autofill:active,
          &:-webkit-autofill:valid  {
            -webkit-box-shadow: 0 0 0 1000px #000 inset !important;
            -webkit-text-fill-color: #fff !important;
            -webkit-transition-delay: 99999s;
            caret-color: #fff;
          }
        }
        .Toastify__toast-theme--light {
          background-color: transparent !important;
        }
        .Toastify__toast {
          box-shadow: 0 0 0 0
        }
        .Toastify__toast-container {
          float: left;
          width: auto;
        }
      `,
    },
  },
});

export default theme;
