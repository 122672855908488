import { styled } from '@mui/material';
export const Wrapper = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: '0',
  left: '0',
  bottom: '0',
  right: '0',
  width: '100%',
  height: '100%',
  zIndex: '1035',
}));

export const DivOverlay = styled('div')(({ theme }) => ({
  width: '100%',
  top: '0',
  left: '0',
  bottom: '0',
  right: '0',
  height: '100%',
  position: 'absolute',
  backgroundColor: 'rgba(25, 25, 25, 0.9)',
  zIndex: '1035',
}));

export const OverlayWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  top: '0',
  left: '0',
  bottom: '0',
  right: '0',
  height: '100%',
  zIndex: '1035',
  position: 'relative',
  overflow: 'auto',
}));

export const WrapperContent = styled('div')(({ theme }) => ({
  width: '100%',
  top: '0',
  left: '0',
  bottom: '0',
  right: '0',
  height: '100%',
  zIndex: '1035',
  position: 'relative',
  overflow: 'auto',
}));
