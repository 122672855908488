import { styled } from '@mui/material';

export const Flag = styled('i')(({ theme }) => ({
  backgroundImage: ' url(/images/dropdown/flags.png)',
  backgroundSize: '5652px 15px',
  width: '20px',
  height: '40px',
  backgroundRepeat: 'no-repeat',
  display: 'inline-block',
  marginRight: '10px',
  '&.ac': {
    backgroundPosition: '0px center',
  },
  '&.ad': {
    height: '14px',
    backgroundPosition: '-22px center',
  },
  '&.ae': {
    backgroundPosition: '-44px center',
  },
  '&.af': {
    height: '14px',
    backgroundPosition: '-66px center',
  },
  '&.ag': {
    height: '14px',
    backgroundPosition: '-88px center',
  },
  '&.ai': {
    backgroundPosition: '-110px center',
  },
  '&.al': {
    height: '15px',
    backgroundPosition: '-132px center',
  },
  '&.am': {
    backgroundPosition: '-154px center',
  },
  '&.ao': {
    height: '14px',
    backgroundPosition: '-176px center',
  },
  '&.aq': {
    height: '14px',
    backgroundPosition: '-198px center',
  },
  '&.ar': {
    height: '13px',
    backgroundPosition: '-220px center',
  },
  '&.as': {
    backgroundPosition: '-242px center',
  },
  '&.at': {
    height: '14px',
    backgroundPosition: '-264px center',
  },
  '&.au': {
    backgroundPosition: '-286px center',
  },
  '&.aw': {
    height: '14px',
    backgroundPosition: '-308px center',
  },
  '&.ax': {
    height: '13px',
    backgroundPosition: '-330px center',
  },
  '&.az': {
    backgroundPosition: '-352px center',
  },
  '&.ba': {
    backgroundPosition: '-374px center',
  },
  '&.bb': {
    height: '14px',
    backgroundPosition: '-396px center',
  },
  '&.bd': {
    height: '12px',
    backgroundPosition: '-418px center',
  },
  '&.be': {
    height: '15px',
    backgroundPosition: '-440px center',
  },
  '&.bf': {
    height: '14px',
    backgroundPosition: '-460px center',
  },
  '&.bg': {
    height: '12px',
    backgroundPosition: '-482px center',
  },
  '&.bh': {
    height: '12px',
    backgroundPosition: '-504px center',
  },
  '&.bi': {
    height: '12px',
    backgroundPosition: '-526px center',
  },
  '&.bj': {
    height: '14px',
    backgroundPosition: '-548px center',
  },
  '&.bl': {
    height: '14px',
    backgroundPosition: '-570px center',
  },
  '&.bm': {
    backgroundPosition: '-592px center',
  },
  '&.bn': {
    backgroundPosition: '-614px center',
  },
  '&.bo': {
    height: '14px',
    backgroundPosition: '-636px center',
  },
  '&.bq': {
    height: '14px',
    backgroundPosition: '-658px center',
  },
  '&.br': {
    height: '14px',
    backgroundPosition: '-680px center',
  },
  '&.bs': {
    backgroundPosition: '-702px center',
  },
  '&.bt': {
    height: '14px',
    backgroundPosition: '-724px center',
  },
  '&.bv': {
    height: '15px',
    backgroundPosition: '-746px center',
  },
  '&.bw': {
    height: '14px',
    backgroundPosition: '-768px center',
  },
  '&.by': {
    backgroundPosition: '-790px center',
  },
  '&.bz': {
    height: '14px',
    backgroundPosition: '-812px center',
  },
  '&.ca': {
    backgroundPosition: '-834px center',
  },
  '&.cc': {
    backgroundPosition: '-856px center',
  },
  '&.cd': {
    height: '15px',
    backgroundPosition: '-878px center',
  },
  '&.cf': {
    height: '14px',
    backgroundPosition: '-900px center',
  },
  '&.cg': {
    height: '14px',
    backgroundPosition: '-922px center',
  },
  '&.ch': {
    height: '15px',
    backgroundPosition: '-944px center',
  },
  '&.ci': {
    height: '14px',
    backgroundPosition: '-961px center',
  },
  '&.ck': {
    backgroundPosition: '-983px center',
  },
  '&.cl': {
    height: '14px',
    backgroundPosition: '-1005px center',
  },
  '&.cm': {
    height: '14px',
    backgroundPosition: '-1027px center',
  },
  '&.cn': {
    height: '14px',
    backgroundPosition: '-1049px center',
  },
  '&.co': {
    height: '14px',
    backgroundPosition: '-1071px center',
  },
  '&.cp': {
    height: '14px',
    backgroundPosition: '-1093px center',
  },
  '&.cr': {
    height: '12px',
    backgroundPosition: '-1115px center',
  },
  '&.cu': {
    backgroundPosition: '-1137px center',
  },
  '&.cv': {
    height: '12px',
    backgroundPosition: '-1159px center',
  },
  '&.cw': {
    height: '14px',
    backgroundPosition: '-1181px center',
  },
  '&.cx': {
    backgroundPosition: '-1203px center',
  },
  '&.cy': {
    height: '14px',
    backgroundPosition: '-1225px center',
  },
  '&.cz': {
    height: '14px',
    backgroundPosition: '-1247px center',
  },
  '&.de': {
    height: '12px',
    backgroundPosition: '-1269px center',
  },
  '&.dg': {
    backgroundPosition: '-1291px center',
  },
  '&.dj': {
    height: '14px',
    backgroundPosition: '-1313px center',
  },
  '&.dk': {
    height: '15px',
    backgroundPosition: '-1335px center',
  },
  '&.dm': {
    backgroundPosition: '-1357px center',
  },
  '&.do': {
    height: '14px',
    backgroundPosition: '-1379px center',
  },
  '&.dz': {
    height: '14px',
    backgroundPosition: '-1401px center',
  },
  '&.ea': {
    height: '14px',
    backgroundPosition: '-1423px center',
  },
  '&.ec': {
    height: '14px',
    backgroundPosition: '-1445px center',
  },
  '&.ee': {
    height: '13px',
    backgroundPosition: '-1467px center',
  },
  '&.eg': {
    height: '14px',
    backgroundPosition: '-1489px center',
  },
  '&.eh': {
    backgroundPosition: '-1511px center',
  },
  '&.er': {
    backgroundPosition: '-1533px center',
  },
  '&.es': {
    height: '14px',
    backgroundPosition: '-1555px center',
  },
  '&.et': {
    backgroundPosition: '-1577px center',
  },
  '&.eu': {
    height: '14px',
    backgroundPosition: '-1599px center',
  },
  '&.fi': {
    height: '12px',
    backgroundPosition: '-1621px center',
  },
  '&.fj': {
    backgroundPosition: '-1643px center',
  },
  '&.fk': {
    backgroundPosition: '-1665px center',
  },
  '&.fm': {
    height: '11px',
    backgroundPosition: '-1687px center',
  },
  '&.fo': {
    height: '15px',
    backgroundPosition: '-1709px center',
  },
  '&.fr': {
    height: '14px',
    backgroundPosition: '-1731px center',
  },
  '&.ga': {
    height: '15px',
    backgroundPosition: '-1753px center',
  },
  '&.gb': {
    backgroundPosition: '-1775px center',
  },
  '&.gd': {
    height: '12px',
    backgroundPosition: '-1797px center',
  },
  '&.ge': {
    height: '14px',
    backgroundPosition: '-1819px center',
  },
  '&.gf': {
    height: '14px',
    backgroundPosition: '-1841px center',
  },
  '&.gg': {
    height: '14px',
    backgroundPosition: '-1863px center',
  },
  '&.gh': {
    height: '14px',
    backgroundPosition: '-1885px center',
  },
  '&.gi': {
    backgroundPosition: '-1907px center',
  },
  '&.gl': {
    height: '14px',
    backgroundPosition: '-1929px center',
  },
  '&.gm': {
    height: '14px',
    backgroundPosition: '-1951px center',
  },
  '&.gn': {
    height: '14px',
    backgroundPosition: '-1973px center',
  },
  '&.gp': {
    height: '14px',
    backgroundPosition: '-1995px center',
  },
  '&.gq': {
    height: '14px',
    backgroundPosition: '-2017px center',
  },
  '&.gr': {
    height: '14px',
    backgroundPosition: '-2039px center',
  },
  '&.gs': {
    backgroundPosition: '-2061px center',
  },
  '&.gt': {
    height: '13px',
    backgroundPosition: '-2083px center',
  },
  '&.gu': {
    height: '11px',
    backgroundPosition: '-2105px center',
  },
  '&.gw': {
    backgroundPosition: '-2127px center',
  },
  '&.gy': {
    height: '12px',
    backgroundPosition: '-2149px center',
  },
  '&.hk': {
    height: '14px',
    backgroundPosition: '-2171px center',
  },
  '&.hm': {
    backgroundPosition: '-2193px center',
  },
  '&.hn': {
    backgroundPosition: '-2215px center',
  },
  '&.hr': {
    backgroundPosition: '-2237px center',
  },
  '&.ht': {
    height: '12px',
    backgroundPosition: '-2259px center',
  },
  '&.hu': {
    backgroundPosition: '-2281px center',
  },
  '&.ic': {
    height: '14px',
    backgroundPosition: '-2303px center',
  },
  '&.id': {
    height: '14px',
    backgroundPosition: '-2325px center',
  },
  '&.ie': {
    backgroundPosition: '-2347px center',
  },
  '&.il': {
    height: '15px',
    backgroundPosition: '-2369px center',
  },
  '&.im': {
    backgroundPosition: '-2391px center',
  },
  '&.in': {
    height: '14px',
    backgroundPosition: '-2413px center',
  },
  '&.io': {
    backgroundPosition: '-2435px center',
  },
  '&.iq': {
    height: '14px',
    backgroundPosition: '-2457px center',
  },
  '&.ir': {
    height: '12px',
    backgroundPosition: '-2479px center',
  },
  '&.is': {
    height: '15px',
    backgroundPosition: '-2501px center',
  },
  '&.it': {
    height: '14px',
    backgroundPosition: '-2523px center',
  },
  '&.je': {
    height: '12px',
    backgroundPosition: '-2545px center',
  },
  '&.jm': {
    backgroundPosition: '-2567px center',
  },
  '&.jo': {
    backgroundPosition: '-2589px center',
  },
  '&.jp': {
    height: '14px',
    backgroundPosition: '-2611px center',
  },
  '&.ke': {
    height: '14px',
    backgroundPosition: '-2633px center',
  },
  '&.kg': {
    height: '12px',
    backgroundPosition: '-2655px center',
  },
  '&.kh': {
    height: '13px',
    backgroundPosition: '-2677px center',
  },
  '&.ki': {
    backgroundPosition: '-2699px center',
  },
  '&.km': {
    height: '12px',
    backgroundPosition: '-2721px center',
  },
  '&.kn': {
    height: '14px',
    backgroundPosition: '-2743px center',
  },
  '&.kp': {
    backgroundPosition: '-2765px center',
  },
  '&.kr': {
    height: '14px',
    backgroundPosition: '-2787px center',
  },
  '&.kw': {
    backgroundPosition: '-2809px center',
  },
  '&.ky': {
    backgroundPosition: '-2831px center',
  },
  '&.kz': {
    backgroundPosition: '-2853px center',
  },
  '&.la': {
    height: '14px',
    backgroundPosition: '-2875px center',
  },
  '&.lb': {
    height: '14px',
    backgroundPosition: '-2897px center',
  },
  '&.lc': {
    backgroundPosition: '-2919px center',
  },
  '&.li': {
    height: '12px',
    backgroundPosition: '-2941px center',
  },
  '&.lk': {
    backgroundPosition: '-2963px center',
  },
  '&.lr': {
    height: '11px',
    backgroundPosition: '-2985px center',
  },
  '&.ls': {
    height: '14px',
    backgroundPosition: '-3007px center',
  },
  '&.lt': {
    height: '12px',
    backgroundPosition: '-3029px center',
  },
  '&.lu': {
    height: '12px',
    backgroundPosition: '-3051px center',
  },
  '&.lv': {
    backgroundPosition: '-3073px center',
  },
  '&.ly': {
    backgroundPosition: '-3095px center',
  },
  '&.ma': {
    height: '14px',
    backgroundPosition: '-3117px center',
  },
  '&.mc': {
    height: '15px',
    backgroundPosition: '-3139px center',
  },
  '&.md': {
    backgroundPosition: '-3160px center',
  },
  '&.me': {
    backgroundPosition: '-3182px center',
  },
  '&.mf': {
    height: '14px',
    backgroundPosition: '-3204px center',
  },
  '&.mg': {
    height: '14px',
    backgroundPosition: '-3226px center',
  },
  '&.mh': {
    height: '11px',
    backgroundPosition: '-3248px center',
  },
  '&.mk': {
    backgroundPosition: '-3270px center',
  },
  '&.ml': {
    height: '14px',
    backgroundPosition: '-3292px center',
  },
  '&.mm': {
    height: '14px',
    backgroundPosition: '-3314px center',
  },
  '&.mn': {
    backgroundPosition: '-3336px center',
  },
  '&.mo': {
    height: '14px',
    backgroundPosition: '-3358px center',
  },
  '&.mp': {
    backgroundPosition: '-3380px center',
  },
  '&.mq': {
    height: '14px',
    backgroundPosition: '-3402px center',
  },
  '&.mr': {
    height: '14px',
    backgroundPosition: '-3424px center',
  },
  '&.ms': {
    backgroundPosition: '-3446px center',
  },
  '&.mt': {
    height: '14px',
    backgroundPosition: '-3468px center',
  },
  '&.mu': {
    height: '14px',
    backgroundPosition: '-3490px center',
  },
  '&.mv': {
    height: '14px',
    backgroundPosition: '-3512px center',
  },
  '&.mw': {
    height: '14px',
    backgroundPosition: '-3534px center',
  },
  '&.mx': {
    height: '12px',
    backgroundPosition: '-3556px center',
  },
  '&.my': {
    backgroundPosition: '-3578px center',
  },
  '&.mz': {
    height: '14px',
    backgroundPosition: '-3600px center',
  },
  '&.na': {
    height: '14px',
    backgroundPosition: '-3622px center',
  },
  '&.nc': {
    backgroundPosition: '-3644px center',
  },
  '&.ne': {
    height: '15px',
    backgroundPosition: '-3666px center',
  },
  '&.nf': {
    backgroundPosition: '-3686px center',
  },
  '&.ng': {
    backgroundPosition: '-3708px center',
  },
  '&.ni': {
    height: '12px',
    backgroundPosition: '-3730px center',
  },
  '&.nl': {
    height: '14px',
    backgroundPosition: '-3752px center',
  },
  '&.no': {
    height: '15px',
    backgroundPosition: '-3774px center',
  },
  '&.np': {
    height: '15px',
    backgroundPosition: '-3796px center',
  },
  '&.nr': {
    backgroundPosition: '-3811px center',
  },
  '&.nu': {
    backgroundPosition: '-3833px center',
  },
  '&.nz': {
    backgroundPosition: '-3855px center',
  },
  '&.om': {
    backgroundPosition: '-3877px center',
  },
  '&.pa': {
    height: '14px',
    backgroundPosition: '-3899px center',
  },
  '&.pe': {
    height: '14px',
    backgroundPosition: '-3921px center',
  },
  '&.pf': {
    height: '14px',
    backgroundPosition: '-3943px center',
  },
  '&.pg': {
    height: '15px',
    backgroundPosition: '-3965px center',
  },
  '&.ph': {
    backgroundPosition: '-3987px center',
  },
  '&.pk': {
    height: '14px',
    backgroundPosition: '-4009px center',
  },
  '&.pl': {
    height: '13px',
    backgroundPosition: '-4031px center',
  },
  '&.pm': {
    height: '14px',
    backgroundPosition: '-4053px center',
  },
  '&.pn': {
    backgroundPosition: '-4075px center',
  },
  '&.pr': {
    height: '14px',
    backgroundPosition: '-4097px center',
  },
  '&.ps': {
    backgroundPosition: '-4119px center',
  },
  '&.pt': {
    height: '14px',
    backgroundPosition: '-4141px center',
  },
  '&.pw': {
    height: '13px',
    backgroundPosition: '-4163px center',
  },
  '&.py': {
    height: '11px',
    backgroundPosition: '-4185px center',
  },
  '&.qa': {
    height: '8px',
    backgroundPosition: '-4207px center',
  },
  '&.re': {
    height: '14px',
    backgroundPosition: '-4229px center',
  },
  '&.ro': {
    height: '14px',
    backgroundPosition: '-4251px center',
  },
  '&.rs': {
    height: '14px',
    backgroundPosition: '-4273px center',
  },
  '&.ru': {
    height: '14px',
    backgroundPosition: '-4295px center',
  },
  '&.rw': {
    height: '14px',
    backgroundPosition: '-4317px center',
  },
  '&.sa': {
    height: '14px',
    backgroundPosition: '-4339px center',
  },
  '&.sb': {
    backgroundPosition: '-4361px center',
  },
  '&.sc': {
    backgroundPosition: '-4383px center',
  },
  '&.sd': {
    backgroundPosition: '-4405px center',
  },
  '&.se': {
    height: '13px',
    backgroundPosition: '-4427px center',
  },
  '&.sg': {
    height: '14px',
    backgroundPosition: '-4449px center',
  },
  '&.sh': {
    backgroundPosition: '-4471px center',
  },
  '&.si': {
    backgroundPosition: '-4493px center',
  },
  '&.sj': {
    height: '15px',
    backgroundPosition: '-4515px center',
  },
  '&.sk': {
    height: '14px',
    backgroundPosition: '-4537px center',
  },
  '&.sl': {
    height: '14px',
    backgroundPosition: '-4559px center',
  },
  '&.sm': {
    height: '15px',
    backgroundPosition: '-4581px center',
  },
  '&.sn': {
    height: '14px',
    backgroundPosition: '-4603px center',
  },
  '&.so': {
    height: '14px',
    backgroundPosition: '-4625px center',
  },
  '&.sr': {
    height: '14px',
    backgroundPosition: '-4647px center',
  },
  '&.ss': {
    backgroundPosition: '-4669px center',
  },
  '&.st': {
    backgroundPosition: '-4691px center',
  },
  '&.sv': {
    height: '12px',
    backgroundPosition: '-4713px center',
  },
  '&.sx': {
    height: '14px',
    backgroundPosition: '-4735px center',
  },
  '&.sy': {
    height: '14px',
    backgroundPosition: '-4757px center',
  },
  '&.sz': {
    height: '14px',
    backgroundPosition: '-4779px center',
  },
  '&.ta': {
    backgroundPosition: '-4801px center',
  },
  '&.tc': {
    backgroundPosition: '-4823px center',
  },
  '&.td': {
    height: '14px',
    backgroundPosition: '-4845px center',
  },
  '&.tf': {
    height: '14px',
    backgroundPosition: '-4867px center',
  },
  '&.tg': {
    height: '13px',
    backgroundPosition: '-4889px center',
  },
  '&.th': {
    height: '14px',
    backgroundPosition: '-4911px center',
  },
  '&.tj': {
    backgroundPosition: '-4933px center',
  },
  '&.tk': {
    backgroundPosition: '-4955px center',
  },
  '&.tl': {
    backgroundPosition: '-4977px center',
  },
  '&.tm': {
    height: '14px',
    backgroundPosition: '-4999px center',
  },
  '&.tn': {
    height: '14px',
    backgroundPosition: '-5021px center',
  },
  '&.to': {
    backgroundPosition: '-5043px center',
  },
  '&.tr': {
    height: '14px',
    backgroundPosition: '-5065px center',
  },
  '&.tt': {
    height: '12px',
    backgroundPosition: '-5087px center',
  },
  '&.tv': {
    backgroundPosition: '-5109px center',
  },
  '&.tw': {
    height: '14px',
    backgroundPosition: '-5131px center',
  },
  '&.tz': {
    height: '14px',
    backgroundPosition: '-5153px center',
  },
  '&.ua': {
    height: '14px',
    backgroundPosition: '-5175px center',
  },
  '&.ug': {
    height: '14px',
    backgroundPosition: '-5197px center',
  },
  '&.um': {
    height: '11px',
    backgroundPosition: '-5219px center',
  },
  '&.un': {
    height: '14px',
    backgroundPosition: '-5241px center',
  },
  '&.us': {
    height: '11px',
    backgroundPosition: '-5263px center',
  },
  '&.uy': {
    height: '14px',
    backgroundPosition: '-5285px center',
  },
  '&.uz': {
    backgroundPosition: '-5307px center',
  },
  '&.va': {
    height: '15px',
    backgroundPosition: '-5329px center',
  },
  '&.vc': {
    height: '14px',
    backgroundPosition: '-5346px center',
  },
  '&.ve': {
    height: '14px',
    backgroundPosition: '-5368px center',
  },
  '&.vg': {
    backgroundPosition: '-5390px center',
  },
  '&.vi': {
    height: '14px',
    backgroundPosition: '-5412px center',
  },
  '&.vn': {
    height: '14px',
    backgroundPosition: '-5434px center',
  },
  '&.vu': {
    height: '12px',
    backgroundPosition: '-5456px center',
  },
  '&.wf': {
    height: '14px',
    backgroundPosition: '-5478px center',
  },
  '&.ws': {
    backgroundPosition: '-5500px center',
  },
  '&.xk': {
    height: '15px',
    backgroundPosition: '-5522px center',
  },
  '&.ye': {
    height: '14px',
    backgroundPosition: '-5544px center',
  },
  '&.yt': {
    height: '14px',
    backgroundPosition: '-5566px center',
  },
  '&.za': {
    height: '14px',
    backgroundPosition: '-5588px center',
  },
  '&.zm': {
    height: '14px',
    backgroundPosition: '-5610px center',
  },
  '&.zw': {
    backgroundPosition: '-5632px center',
  },
}));
