//@ts-nocheck
import { checkPhoneNumber, updateUser } from '@/api/user';
import { Text } from '@/components/common';
import Button from '@/components/common/Button';
import { FormMarginDense } from '@/components/form/FormMarginDense';
import { FormWrapper } from '@/components/form/FormWrapper';
import PhoneField from '@/components/form/PhoneField';
import { sendVerificationCode } from '@/libs/firebase';
import VerificationPhoneCodeModal from '@/modal/VerificationPhoneCode';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { ERROR_CODES } from '@/utils/constant';
import { ModalRenderer } from '@/utils/modalUtils';
import { PhoneNumberSchema } from '@/utils/validation';
import { Formik } from 'formik';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { get } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { StyledForm } from './styled';
import userReducer from '@/redux/reducers/userReducer';

const schema = yup.object().shape({
  phoneNumber: PhoneNumberSchema,
});

const VerifyPhoneNumberPage: React.FC<any> = () => {
  // const reCapchaContainerElement = useRef(null);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const formikRef = useRef();

  const userId = useAppSelector((state: any) => get(state, 'user.userInfo.id'));
  const userInfo = useAppSelector((state: any) => state.user.userInfo);
  const phoneNumber = parsePhoneNumberFromString('+' + userInfo?.phone_number)?.nationalNumber?.toString();
  const countryCode = parsePhoneNumberFromString('+' + userInfo?.phone_number)?.countryCallingCode?.toString();
  const [phoneCode, setPhoneCode] = useState(countryCode ?? '44');

  useEffect(() => {
    dispatch({ type: 'HIDE_LOADING' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const clearRecapchaContent = () => {
  //   if (reCapchaContainerElement.current) {
  //     reCapchaContainerElement.current.innerHTML = '<div id="recapcha-container"></div>';
  //   }
  // };

  const onSubmit = async (values: any) => {
    const phoneNumber = '+' + phoneCode + values.phoneNumber;
    // clearRecapchaContent();
    try {
      dispatch({ type: 'SHOW_LOADING' });
      if (!userInfo?.phone_number) {
        await checkPhoneNumber(phoneNumber);
      }
      const user = await updateUser(userId, { phone_number: phoneNumber });
      dispatch(userReducer.actions.setUserInfo(user));
      const isSendVerificationSuccess = await sendVerificationCode(phoneNumber);
      if (isSendVerificationSuccess) {
        ModalRenderer.open({
          component: VerificationPhoneCodeModal,
          theme: 'white',
          inMobile: 'bottom',
          props: {
            phoneNumber: phoneNumber
          },
        });
      }
    } catch (error) {
      if (error === ERROR_CODES.INVALID_PHONE_NUMBER || error === ERROR_CODES.EXISTING_PHONE_NUMBER) {
        formikRef.current.setFieldError('phoneNumber', t('ERROR_MESSAGE_INVALID_PHONE_NUMBER'));
        return
      }
      if (error === ERROR_CODES.TOO_MANY_REQUESTS || error === ERROR_CODES.TOO_MANY_REQUEST_CODE) {
        formikRef.current.setFieldError('phoneNumber', t('ERROR_MESSAGE_TOO_MANY_REQUESTS'));
        return
      }
      if (error === ERROR_CODES.TOO_MANY_ATTEMPS_CODE) {
        formikRef.current.setFieldError('phoneNumber', t('ERROR_MESSAGE_TOO_MANY_ATTEMPS_CODE'));
        return
      }
      formikRef.current.setFieldError('phoneNumber', t('ERROR_MESSAGE_SOMETHING_WENT_WRONG'));
    } finally {
      dispatch({ type: 'HIDE_LOADING' });
    }
  };

  return (
    <>
      <FormWrapper>
        <Text color="darkPrimary" variant="title">
          {t('VERIFY_YOUR_PHONE_NUMBER')}
        </Text>
        <Text color="darkSecondary" variant="content">
          {t('VERIFY_YOUR_PHONE_NUMBER_SUBTITLE')}
        </Text>
        <Formik
          initialValues={{ phoneNumber: phoneNumber }}
          validationSchema={schema}
          onSubmit={onSubmit}
          innerRef={formikRef}
        >
          {(props) => (
            <StyledForm>
              <div>
                {/* Phone number */}
                <FormMarginDense>
                  <PhoneField
                    type="tel"
                    name="phoneNumber"
                    config={{
                      label: t('PHONE_NUMBER'),
                      InputProps: {
                        'data-testid': 'test_phone',
                      },
                    }}
                    onHandlePhoneCode={setPhoneCode}
                    PhoneCode={phoneCode}
                    formik={props}
                  />
                </FormMarginDense>
              </div>
              <FormMarginDense>
                <Button
                  fullWidth
                  variant="contained"
                  color="white"
                  type="submit"
                  disabled={!!Boolean(props.errors.phoneNumber)}
                  data-testid="test_btn_continue"
                >
                  {t('CONTINUE')}
                </Button>
              </FormMarginDense>
            </StyledForm>
          )}
        </Formik>
      </FormWrapper>
      {/* <RecapchaContainer ref={reCapchaContainerElement}>
        <div id="recapcha-container" />
      </RecapchaContainer> */}
    </>
  );
};

export default VerifyPhoneNumberPage;
